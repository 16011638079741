import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import { useEffect } from 'react';

import { authStore } from 'Stores';

import { FeedbackPage, ProfilePage, MainPage, SuccessfulSubmissionPage } from 'Modules';

import ClaimRoutes from './ClaimRoutes';
import { Routes } from 'Shared/Constants/Constants';

const MainRoutes = () => {
  const photoProfile = authStore.photoProfile;

  useEffect(() => {
    if (!photoProfile) {
      authStore.getUserPhoto();
    }
  }, [photoProfile]);

  return (
    <>
      <Route path={Routes.MAIN} component={MainPage} exact={true} />
      <Route path={Routes.PROFILE} component={ProfilePage} exact={true} />
      <Route path={Routes.FEEDBACK} component={FeedbackPage} exact={true} />
      <Route path={Routes.SUCCESSFUL} component={SuccessfulSubmissionPage} exact={true} />
      <ClaimRoutes />
    </>
  );
};

export default observer(MainRoutes);
