import { makeStyles } from '@material-ui/core';
import { COLORS } from 'Shared/Constants/Colors';

export const useStyles = makeStyles(theme => ({
  feedbackPage: {
    position: 'relative',
  },
  feedbackTitle: {
    margin: theme.spacing(3.5, 'auto'),
  },
  feedbackText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    letterSpacing: -0.24,
    color: theme.palette.common.black,
    margin: theme.spacing(3.5, 'auto'),
    textAlign: 'left',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  control: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    height: 48,
    color: theme.palette.common.white,
    margin: theme.spacing(2, 0),
    background: COLORS.BaseGreen,
    boxShadow: '0px 3px 0px #1A6334',
    borderRadius: 24,
    border: 'none',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 17,
    letterSpacing: -0.5,
    fontFamily: theme.typography.fontFamily,
    padding: '6px 8px',
    '&:hover': {
      background: COLORS.BaseGreen,
    },
  },
  photo: {
    position: 'relative',
    left: 0,
    top: 2,
    color: 'rgba(0, 0, 0, 0.6)',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    background: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.palette.common.white,
    },
  },
  preview: {
    position: 'relative',
    bottom: 0,
  },
  iconAttach: {
    transform: 'rotate(-45deg)',
  },
}));
