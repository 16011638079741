import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from 'Shared/Constants/Constants';

import useStyles from './styles';

const Links = () => {
  const c = useStyles();

  return (
    <ul className={c.link}>
      <Link to={Routes.FEEDBACK}>
        <li>Связаться с разработчиком</li>
      </Link>
      <Link to={Routes.COMMON.AGREEMENTS}>
        <li>Соглашения с пользователем</li>
      </Link>
      {/* TODO В дальнейшем будет использоваться
			<li>О приложении</li> */}
    </ul>
  );
};

export default React.memo(Links);
