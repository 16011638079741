import { observer } from 'mobx-react';
import { Avatar } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { authStore } from 'Stores';

import useStyles from './styles';

export interface IProps {
  spacing: number;
}

const UserAvatar = (props: IProps) => {
  const c = useStyles(props);

  const avatar = authStore.photoProfile;

  return (
    <Avatar alt="avatar" src={avatar?.preview || ''} className={c.avatar}>
      {!avatar && <AccountCircleIcon className={c.img} />}
    </Avatar>
  );
};

export default observer(UserAvatar);
