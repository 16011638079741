import { Method } from 'Shared/Enums/enums';
import { LOCATION_REQUEST_DATA } from 'Shared/Constants/Constants';

class LocationService {
  public baseUrl = LOCATION_REQUEST_DATA.URL;
  public token = LOCATION_REQUEST_DATA.API_KEY;

  public getLocation<T>(lon: number, lat: number): Promise<T> {
    const body = JSON.stringify({ lat, lon });

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + this.token,
    };

    const options = { headers, body, method: Method.Post };

    return fetch(this.baseUrl, options).then(async response => {
      if (!response.ok) {
        throw await 'Что-то пошло не так...';
      }
      return response.json();
    });
  }
}

export default new LocationService();
