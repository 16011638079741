import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';

import { appStore, claimsStore, mediaStore } from 'Stores';

import { ButtonApp, Header } from 'Components';
import { AdDialog, CarDialog, TrashDialog } from './dialogItems';

import { RoutesNumber, TypeOfNotification } from 'Shared/Enums/enums';
import { Routes } from 'Shared/Constants/Constants';

import useStyles from './styles';
import { CloseOutlined } from '@material-ui/icons';

const DialogPage = () => {
  const routeNumber = appStore.routeNumber;
  const city = claimsStore.address?.suggestions?.length && claimsStore.address.suggestions[0].data.city;
  const success = claimsStore.status;

  const c = useStyles();
  const history = useHistory();

  const address = claimsStore.address?.suggestions?.length
    ? claimsStore.address.suggestions[0].value
    : 'Адрес не определен';
  const coordinates = claimsStore.coordinates;

  useEffect(() => {
    claimsStore.getAddress();
  }, []);

  useEffect(() => {
    if (success) {
      history.push(Routes.SUCCESSFUL);
    }
  }, [success, history]);

  useEffect(() => {
    if (city !== 'Томск') {
      appStore.showNotification('Заявки о правонарушении \nпринимаются только из Томска', TypeOfNotification.Info);
    } else appStore.hideNotification();
  }, [city]);

  const getTitle = () => {
    switch (routeNumber) {
      case RoutesNumber.CarRoute:
        return 'Сообщение о парковке в неположенном месте';
      case RoutesNumber.AdRoute:
        return 'Сообщение о рекламе в неположенном месте';
      case RoutesNumber.TrashRoute:
        return 'Сообщение о мусоре в неположенном месте';
    }

    return 'Результат фиксации';
  };

  const getDialogItems = () => {
    switch (routeNumber) {
      case RoutesNumber.CarRoute:
        return CarDialog(
          mediaStore.medias.map(p => p.preview),
          address,
          coordinates,
          claimsStore.carData
        );
      case RoutesNumber.AdRoute:
        return AdDialog(
          mediaStore.medias.map(p => p.preview),
          address,
          coordinates
        );
      case RoutesNumber.TrashRoute:
        return TrashDialog(
          mediaStore.medias.map(p => {
            return { photo: p.preview, isVideo: p.isVideo };
          }),
          address,
          coordinates
        );
    }
  };

  const onClick = () => {
    history.push(Routes.MAIN);
    claimsStore.clearStore();
  };

  const sendData = async () => {
    switch (routeNumber) {
      case RoutesNumber.CarRoute:
        return claimsStore.sendParking();
      case RoutesNumber.AdRoute:
        return claimsStore.sendAdvertising();
      case RoutesNumber.TrashRoute:
        return await claimsStore.sendTrash();
    }
  };

  const onClose = () => {
    history.push(Routes.MAIN);
  };

  return (
    <div className={c.dialog}>
      <Header isProfile={true} settings={false} />

      <Tooltip title="Закрыть" placement="left">
        <Button className={c.closeButton} onClick={onClose}>
          <CloseOutlined style={{ color: 'gray' }} />
        </Button>
      </Tooltip>

      <Container>
        <h1 className={c.title}>{getTitle()}</h1>

        <ul className={c.dialogBlock}>
          {getDialogItems()!.items.map(item => (
            <li key={item.id}>
              <p className={c.dialogTitle}>{item.title}</p>
              <div className={c.dialogBlockContainer}>{item.component}</div>
            </li>
          ))}
        </ul>

        <ButtonApp disabled={city !== 'Томск'} onClick={sendData}>
          {getDialogItems()!.buttonName}
        </ButtonApp>

        <ButtonApp className={c.button} onClick={onClick}>
          Отменить
        </ButtonApp>
      </Container>
    </div>
  );
};

export default observer(DialogPage);
