import moment from 'moment';
import { Moment } from 'moment';
import { ClaimStatuses } from 'Shared/Enums/enums';
import { IGetUserData } from 'Shared/Interfaces/IGetDto';
import { IMedia } from 'Shared/Interfaces/Interfaces';
import { PhoneModel } from 'Shared/Models';

export default class Utils {
  /**
   * Конвертация из строки base64 в File
   * @param photoData
   * @returns
   */
  public static stringToFile(photoData: string): IMedia {
    const arr = photoData.split(',');
    const mime = arr[0]!.match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const fileId = `cmr${(+new Date()).toString(16)}`;
    const photo = {
      id: fileId,
      photo: new File([u8arr], fileId, { type: mime }),
      preview: photoData,
      isVideo: false,
    };
    return photo;
  }

  /**
   * Изменения окончания слова 'правонарушения' в зависимости от количества (до 100)
   * @arg количество правонарушений
   * @returns окончание слова
   */
  public static getTheEndOfAWord(arg: number | undefined): string {
    if (!arg) return 'ий';

    let temp = '';

    if (arg >= 5 || arg === 0 || arg === 3) temp = 'ий';
    else if (arg === 1) temp = 'ие';
    else temp = 'ия';

    return temp;
  }

  public static constructPhoneString(phoneNumbers: PhoneModel[]) {
    let advertisingPhones = '';

    phoneNumbers.map(phone => {
      let p = phone.phone;
      // варианты (8/7)999 999 99 99 и (8/7) 3822 999 999
      if (p.length === 11) {
        if (p[1] === '9') {
          if (p[0] === '8') p = p.replace('8', '7');
          advertisingPhones += `+${p};`;
        } else if (p[1] === '3' && p[2] === '8') {
          if (p[0] === '8') p = p.replace('8', '7');
          advertisingPhones += `+${p};`;
        }
      } else if (p.length === 10) {
        advertisingPhones += `+7${p};`;
      } else if (p.length === 6) {
        advertisingPhones += `+73822${p};`;
      }
      return null;
    });

    return advertisingPhones;
  }

  public static getFullName(userData: IGetUserData | null) {
    if (!userData) return '';
    return `${userData.firstName} ${userData.middleName} ${userData.lastName}`;
  }

  /**
   * Обобщенный метод сортировки
   * @param isAscending - направление сортировки
   * @param list - сортируемый список
   * @param propertyName - критерий сортировки, поле класса Т
   */
  public static sort = <T>(isAscending: boolean, list: T[], propertyName: keyof T): T[] => {
    try {
      if (propertyName === 'formattedStartedAt') {
        return isAscending
          ? list.slice().sort((a, b) => Utils.ascDateComparator((a as any).startedAt, (b as any).startedAt))
          : list.slice().sort((a, b) => Utils.descDateComparator((a as any).startedAt, (b as any).startedAt));
      }
    } catch (e) {
      console.log(e);
    }

    return isAscending
      ? list.slice().sort((a, b) => Utils.ascComparator(a, b, propertyName))
      : list.slice().sort((a, b) => Utils.descComparator(a, b, propertyName));
  };

  /**
   * Компоратор для сортировки по возрастанию
   * @param leftItem - левый операнд
   * @param rightItem - правый операнд
   * @param propertyName - поле объекта для сравнения
   */
  public static ascComparator = <T>(leftItem: T, rightItem: T, propertyName: keyof T): number => {
    // if (!leftItem[propertyName]) return -1;

    if (leftItem[propertyName] < rightItem[propertyName]) {
      return -1;
    }
    if (leftItem[propertyName] > rightItem[propertyName]) {
      return 1;
    }
    return 0;
  };

  /**
   * Компоратор для сортировки по убыванию
   * @param leftItem - левый операнд
   * @param rightItem - правый операнд
   * @param propertyName - поле объекта для сравнения
   */
  public static descComparator = <T>(leftItem: T, rightItem: T, propertyName: keyof T): number => {
    if (leftItem[propertyName] > rightItem[propertyName]) {
      return -1;
    }
    if (leftItem[propertyName] < rightItem[propertyName]) {
      return 1;
    }
    return 0;
  };

  /**
   * Компоратор для сортировки даты по возрастанию
   * @param leftItem - левый операнд
   * @param rightItem - правый операнд
   * @param propertyName - поле объекта для сравнения
   */
  public static ascDateComparator = (leftItem: string, rightItem: string): number => {
    if (moment(leftItem).isBefore(rightItem)) {
      return -1;
    }
    if (moment(leftItem).isAfter(rightItem)) {
      return 1;
    }
    return 0;
  };

  /**
   * Компоратор для сортировки даты по убыванию
   * @param leftItem - левый операнд
   * @param rightItem - правый операнд
   * @param propertyName - поле объекта для сравнения
   */
  public static descDateComparator = (leftItem: string, rightItem: string): number => {
    if (new Date(leftItem) > new Date(rightItem)) {
      return -1;
    }
    if (new Date(leftItem) < new Date(rightItem)) {
      return 1;
    }
    return 0;
  };

  /**
   * Преобразование даты в UTC
   * @param date
   * @returns
   */
  public static toUtcDateString(date: Date | string | Moment) {
    const utcDate = moment.utc(date).toISOString();
    return utcDate.substring(0, utcDate.indexOf('Z'));
  }

  public static getStateName(stateId: number | string): string {
    switch (stateId) {
      case ClaimStatuses.Accepted:
        return 'Принято';
      case ClaimStatuses.Declined:
        return 'Отклонено';
      case ClaimStatuses.Finished:
        return 'Работа завершена';
      case ClaimStatuses.Registered:
        return 'Зарегистрировано';
      case ClaimStatuses.Pending:
        return 'В ожидании';
      default:
        return 'Нет данных';
    }
  }
}
