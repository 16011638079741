import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 117,
  },
  userRating: {
    display: 'flex',
    alignItems: 'center',
  },
  ratingValue: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    letterSpacing: '-0.24px',
    color: '#828282',
    marginLeft: 4,
    paddingTop: 2,
  },
}));

export default useStyles;
