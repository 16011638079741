import { action, makeObservable, observable } from 'mobx';

import { appStore, authStore, mediaStore } from 'Stores';

import {
  AdvertisingService,
  ClaimService,
  LocationService,
  ParkingService,
  TrashService,
  VideoService,
} from 'ApiServices';

import { ICarDataDto, IClaimsOfTheDay, ICoordinates } from 'Shared/Interfaces/Interfaces';
import { IGetLocationDto } from 'Shared/Interfaces/IGetDto';
import { PhoneModel } from 'Shared/Models';
import { TypeOfNotification } from 'Shared/Enums/enums';
import { DEFAULT_MAP_CENTER } from 'Shared/Constants/Constants';
import Utils from 'Shared/Utils/Utils';

class ClaimsStore {
  public coordinates = DEFAULT_MAP_CENTER;
  public imageCoordinates: ICoordinates | null = null;
  public phoneNumbers: PhoneModel[] = [new PhoneModel()];
  public carData: ICarDataDto = { carNumber: '', regionCode: '' };
  public address: IGetLocationDto | null = null;
  public claims: IClaimsOfTheDay[] = [];
  public status = false;

  constructor() {
    makeObservable(this, {
      status: observable,
      coordinates: observable,
      imageCoordinates: observable,
      phoneNumbers: observable,
      carData: observable,
      address: observable,
      claims: observable,

      getPosition: action,
      getAddress: action,
      getClaims: action,
      changePhoneNumbers: action,
      changeCarNumber: action,
      changeRegionCode: action,
      changeAddreess: action,
      sendAdvertising: action,
      clearStore: action,
      getImageCoordinates: action,
    });
  }

  /**
   *  получение координат местоположения пользователя
   * @position - координаты местоположения пользователя
   */
  public getPosition(position: ICoordinates) {
    this.coordinates = position;
  }

  public getImageCoordinates(position: ICoordinates) {
    this.imageCoordinates = position;
  }

  /**
   * изменение номера телефона
   * @phone - номер телефона
   */
  public changePhoneNumbers(newValue: PhoneModel[]) {
    this.phoneNumbers = newValue;
  }

  /**
   * изменение номера машины
   * @number - номер машины
   */
  public changeCarNumber(carNumber: string) {
    this.carData.carNumber = carNumber;
  }

  /**
   * изменение кода региона
   * @code - код
   */
  public changeRegionCode(code: string) {
    this.carData.regionCode = code;
  }

  /**
   * изменение адреса
   * @address - адрес
   */
  public changeAddreess(address: IGetLocationDto) {
    this.address = address;
  }

  /** получение адреса по координатам с Dadata */
  public getAddress() {
    const lon = this.coordinates.lng;
    const lat = this.coordinates.lat;

    LocationService.getLocation<IGetLocationDto>(lon, lat)
      .then(res => this.changeAddreess(res))
      .catch(err => console.log(err));
  }

  /**
   * получение всех заявок
   * @claims - нарушения
   */
  public getClaims(claims: IClaimsOfTheDay[]) {
    this.claims = this.claims.concat(claims);
  }

  /**
   * получение полной информации по фиксации объявления/мусора/парковки
   * @claims - нарушения
   */
  public async getClaim(id: number) {
    appStore.hideNotification();
    appStore.showLoader();

    try {
      const dto = await ClaimService.getClaim(id);
      return dto;
    } catch (error) {
      appStore.showNotification('Заявка не отправлена.\nПовторите через полчаса.', TypeOfNotification.Error);
    } finally {
      appStore.hideLoader();
    }
  }

  /** Отправка рекламы */
  public sendAdvertising() {
    if (!authStore.userData) return;
    const notEmptyPhones = this.phoneNumbers.filter(p => p.phone !== '');

    if (notEmptyPhones.length === 0) {
      appStore.showNotification('Необходимо указать номер телефона.', TypeOfNotification.Warning);
      return;
    }

    notEmptyPhones.map(ph => ph.validate());
    if (notEmptyPhones.some(p => !p.isValid)) {
      appStore.showNotification('Проверьте номер телефона.', TypeOfNotification.Warning);
      return;
    }
    appStore.hideNotification();
    appStore.showLoader();
    this.status = false;

    const body = {
      userId: authStore.userData.id,
      latitude: this.coordinates.lat,
      longitude: this.coordinates.lng,
      address: this.address?.suggestions[0].value,
      offenseId: appStore.offensesId,
      advertisingPhone: Utils.constructPhoneString(notEmptyPhones),
      photos: [mediaStore.medias[0].preview, mediaStore.medias[1].preview],
    };

    AdvertisingService.sendAdvertising(body)
      .then(() => {
        this.clearStore();
        this.status = true;
      })
      .catch(() => {
        appStore.showNotification('Произошла ошибка\nПопробуйте позже', TypeOfNotification.Error);
      })
      .finally(() => {
        appStore.hideLoader();
        this.status = false;
      });
  }

  /** Отправка мусора */
  public async sendTrash() {
    if (!authStore.userData) return;

    this.status = false;

    const photos = mediaStore.medias.filter(m => !m.isVideo).map(p => p.preview);
    const videos = mediaStore.medias.filter(m => m.isVideo);

    const body = {
      userId: authStore.userData.id,
      latitude: this.coordinates.lat,
      longitude: this.coordinates.lng,
      address: this.address?.suggestions[0].value,
      offenseId: 4,
      photos,
      videoCount: videos.length,
    };

    try {
      const response = await TrashService.addTrashClaim(body);

      response.videoIds.forEach((videoId, index) => {
        const sendingVideo = videos[index];
        const isLast = videos.length === index + 1;

        if (sendingVideo && sendingVideo.photo) {
          let attemptsCount = 3;
          while (attemptsCount !== 0) {
            try {
              VideoService.addVideo({ id: response.offenseId, file: sendingVideo.photo, isLast })
                .then(() => { attemptsCount = 0; })
                .catch(() => { attemptsCount--; });
              attemptsCount = 0;
            } catch { attemptsCount--; }
          }
        }
      });

      this.clearStore();
      this.status = true;
    } catch {
      appStore.showNotification('Произошла ошибка\nПопробуйте позже', TypeOfNotification.Error);
    }
    appStore.hideLoader();
    this.status = false;
  }

  /** Отправка парковки */
  public sendParking() {
    if (!authStore.userData) return;
    appStore.showLoader();
    this.status = false;

    const body = {
      userId: authStore.userData.id,
      latitude: this.coordinates.lat,
      longitude: this.coordinates.lng,
      address: this.address?.suggestions[0].value,
      offenseId: appStore.offensesId,
      carNumber: this.carData.carNumber,
      regionCode: this.carData.regionCode,
      photos: [mediaStore.medias[0].preview, mediaStore.medias[1].preview],
    };

    ParkingService.sendParking(body)
      .then(() => {
        this.clearStore();
        this.status = true;
      })
      .catch(() => {
        appStore.showNotification('Произошла ошибка\nПопробуйте позже', TypeOfNotification.Error);
      })
      .finally(() => {
        appStore.hideLoader();
        this.status = false;
      });
  }

  /** Очистка Store */
  public clearStore() {
    this.carData = { carNumber: '', regionCode: '' };
    this.phoneNumbers = [new PhoneModel()];
    this.coordinates = DEFAULT_MAP_CENTER;
    mediaStore.clearStore();
  }
}

export default new ClaimsStore();
