import { Coordinates, CustomTable, Photos } from 'Components';
import FullScreenImage from 'Components/FullScreenImage';
import { CarNumber } from 'Modules/DialogPage/Components';
import moment from 'moment';
import { IClaimDetailDto, IDialogProps } from 'Shared/Interfaces/Interfaces';
import { IStateHistoryDto, ITableColumn } from 'Shared/Interfaces/Interfaces';
import Utils from 'Shared/Utils/Utils';

const columns: ITableColumn[] = [
  {
    key: 'date',
    label: 'Дата',
    sortable: true,
    minWidth: '80px',
    align: 'left',
    cell: (row: IStateHistoryDto) => <div>{moment(row.date).format('DD.MM.YYYY HH:mm')}</div>,
  },
  {
    key: 'stateId',
    label: 'Статус',
    sortable: true,
    minWidth: '120px',
    align: 'left',
    cell: (row: IStateHistoryDto) => <div>{Utils.getStateName(row.stateId)}</div>,
  },
  {
    key: 'comment',
    label: 'Причина отклонения',
    sortable: true,
    minWidth: '90px',
    align: 'left',
    cell: (row: IStateHistoryDto) => <div>{row.comment}</div>,
  },
  // {
  //   key: 'icon',
  //   label: '',
  //   sortable: false,
  //   width: '60px',
  //   align: 'left',
  // },
];

export const AdDialog = (claim: IClaimDetailDto, className: string) => {
  const transformedPhones = claim.advertisingReport.advertisingPhone?.replaceAll(';', ' ');
  const photos = claim.photos.map(p => {
    return { photo: p.base64String, isVideo: false };
  });

  const result: IDialogProps = {
    items: [
      {
        title: '1. Какие телефоны указаны в объявлении?',
        component: <div className={className}>{transformedPhones}</div>,
        id: 1,
      },
      {
        title: '2. Фото объявления и места',
        component: (
          <>
            <Photos photos={photos} />
            <FullScreenImage photos={photos} />
          </>
        ),
        id: 2,
      },
      {
        title: '3. Координаты места',
        component: (
          <Coordinates
            address={claim.address || ''}
            coordinates={{ lng: claim.longitude, lat: claim.latitude }}
            withMap={true}
          />
        ),
        id: 3,
      },
      {
        title: '4. История перемещения по статусам',
        component: <CustomTable data={claim.stateHistories} columns={columns} />,
        id: 4,
      },
    ],
  };
  return result;
};

export const CarDialog = (claim: IClaimDetailDto, className: string) => {
  const photos = claim.photos.map(p => {
    return { photo: p.base64String, isVideo: false };
  });

  const result: IDialogProps = {
    items: [
      {
        id: 1,
        title: '1. Госномер автомобиля-нарушителя',
        component: (
          <CarNumber
            carNumber={claim.parkingReport.carNumber}
            regionCode={claim.parkingReport.regionCode}
            isOnlyView={true}
          />
        ),
      },
      {
        title: '2. Фото автомобиля и места',
        component: (
          <>
            <Photos photos={photos} />
            <FullScreenImage photos={photos} />
          </>
        ),
        id: 2,
      },
      {
        title: '3. Категория правонарушения',
        component: <div className={className}>{claim.description}</div>,
        id: 3,
      },
      {
        title: '4. Координаты места',
        component: (
          <Coordinates
            address={claim.address || ''}
            coordinates={{ lng: claim.longitude, lat: claim.latitude }}
            withMap={true}
          />
        ),
        id: 4,
      },
      {
        title: '5. История перемещения по статусам',
        component: <CustomTable data={claim.stateHistories} columns={columns} />,
        id: 6,
      },
    ],
  };
  return result;
};

export const TrashDialog = (claim: IClaimDetailDto) => {
  const photos = claim.photos.map(p => {
    return { photo: p.base64String, isVideo: false };
  });

  const result: IDialogProps = {
    items: [
      {
        title: '1. Фотографии мусора',
        component: (
          <>
            <Photos photos={photos} />
            <FullScreenImage photos={photos} />
          </>
        ),
        id: 1,
      },
      {
        title: '2. Координаты места',
        component: (
          <Coordinates
            address={claim.address || ''}
            coordinates={{ lng: claim.longitude, lat: claim.latitude }}
            withMap={true}
          />
        ),
        id: 2,
      },
      {
        title: '3. История перемещения по статусам',
        component: <CustomTable data={claim.stateHistories} columns={columns} />,
        id: 3,
      },
    ],
  };
  return result;
};
