export enum RoutesNumber {
  DefaultRoute = 0,
  CarRoute = 1,
  AdRoute = 2,
  TrashRoute = 3,
}

export enum TypeOfNotification {
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
}

export enum Method {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export enum ClaimStatuses {
  Pending = 1,
  Accepted = 2,
  Declined = 3,
  Finished = 4,
  Registered = 5,
}

export enum ClaimTypes {
  ParkingClaim = 1,
  AdvertisingClaim = 2,
  RubbishClaim = 3,
}

export enum PhotoStorageLocation {
  Photos,
  PhotoProfile,
  PhotoFeedback,
}

export enum LocalStorageItems {
  Token = 'Jwt',
  UserData = 'UserData',
  EsiaState = 'esiaState',
  EsiaAccessCode = 'esiaCode',
}
