import { makeStyles, Theme } from '@material-ui/core';
import { IHeaderStyleOptions } from './Components/ProfileHeader';

const useStyles = makeStyles<Theme, IHeaderStyleOptions>(theme => ({
  header: {
    height: 44,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      backgroundColor: '#ffff',
      boxShadow: 'none',
    },
  },
  toolbar: {
    '@media (max-width: 475px)': {
      minHeight: 44,
    },
    justifyContent: 'space-between',
  },
  toolbarLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 130,
  },
  toolbarRight: {},
  smallSettings: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  name: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: '-0.41px',
    color: theme.palette.common.black,
    marginLeft: 5,
  },
  button: props => ({
    background: '#FFFF',
    fontFamily: theme.typography.fontFamily,
    fontSize: 17,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.408px',
    color: props.color ?? theme.palette.primary.main,
    textTransform: 'none',
    width: 60,
    '& span': {
      marginRight: 0,
    },

    '@media (max-width: 355px)': {
      fontSize: 15,
    },
  }),
  buttonArrow: props => ({
    width: 14,
    height: 14,
    border: `2.5px solid ${props.color ?? theme.palette.primary.main}`,
    transform: 'rotate(45deg)',
    borderTop: 'none',
    borderRight: 'none',
    animation: '$buttonArrow 0.5s forwards ease-out',
  }),
  '@keyframes buttonArrow': {
    '0%': {
      transform: 'rotate(-45deg) translateX(500px)',
    },
    '100%': {
      transform: 'rotate(45deg) translateX(0)',
    },
  },
}));

export default useStyles;
