import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  appBar: {
    background: theme.palette.common.white,
    alignItems: 'center',
    justifyContent: 'space-between',
    top: 44,
    boxShadow: '0 0 0 0 rgb(0 0 0 / 0%), 1px 3px 5px 0px rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 0%)',
  },
  tab: {
    maxWidth: 105,
    fontSize: 12,
    lineHeight: 1.1,
  },
}));
