import HTTPService from './HttpService';
import { BASE_PATH } from 'Shared/Constants/Constants';
import { IEsiaAuthLinkDto, IEsiaUserData } from 'Shared/Interfaces/IGetDto';

class EsiaService extends HTTPService {
  constructor() {
    super(BASE_PATH.ESIA);
  }

  public getAuthorizeLink() {
    return this.GET<IEsiaAuthLinkDto>('/auth-link');
  }

  public getAuthUser(code: string) {
    return this.POST<IEsiaUserData>('/auth', JSON.stringify(code));
  }
}

export default new EsiaService();
