import HTTPService from './HttpService';

import { BASE_PATH } from 'Shared/Constants/Constants';
import { IAdvertisingData } from 'Shared/Interfaces/Interfaces';

class AdvertisingService extends HTTPService {
  constructor() {
    super(BASE_PATH.ADVERTISING);
  }

  public sendAdvertising(body: IAdvertisingData) {
    return this.POST<string>(``, JSON.stringify(body));
  }
}

export default new AdvertisingService();
