import { makeStyles, Theme } from '@material-ui/core';
import { IHeaderStyleOptions } from './CustomTable';

const useStyles = makeStyles<Theme, IHeaderStyleOptions>(theme => ({
  reportTable: props => ({
    display: 'flex',
    maxHeight: props.height,
    flexDirection: 'column',
    flex: 1,
    // justifyContent: 'space-between',
  }),
  tableContainer: {
    flex: 1,
    overflow: 'auto',
  },
  head: {
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.type === 'dark' ? '#424242' : '#fff',
      color: '#9EA0AD',
      borderBottom: 'none',
      paddingRight: 2,
      '&:first-child': {
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      },
      '&:last-child': {
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
      },
      '& .MuiTableSortLabel-active, .MuiTableSortLabel-icon': {
        color: '#9EA0AD !important',
        opacity: '0,5',
      },
    },
  },
  tableCell: props => ({
    '&:first-of-type': {
      paddingLeft: props.tableCellPadding,
    },
  }),
  tableRow: {
    '& .MuiTableCell-root[colspan]': {
      borderBottom: 'none',
    },
  },
}));

export default useStyles;
