import { action, makeObservable, observable } from 'mobx';

import { appStore, authStore, mediaStore } from 'Stores';

import { FeedbackService } from 'ApiServices';

import { TypeOfNotification } from 'Shared/Enums/enums';
import { IFeedbackData, IFeedbackFormData } from 'Shared/Interfaces/Interfaces';

class FeedbackStore {
  public isSuccess = false; // Это для того, чтобы чистить поля у formik и выйти на страницу профиля

  constructor() {
    makeObservable(this, {
      isSuccess: observable,
      init: action,
      sendFeedbackData: action,
      clearStore: action,
    });
  }

  public init() {
    this.isSuccess = false;
  }

  public clearStore() {
    this.isSuccess = true;
    mediaStore.clearFeedbackPhoto();
  }

  public sendFeedbackData(formData: IFeedbackFormData) {
    if (!authStore.userData) return;

    appStore.showLoader();
    appStore.hideNotification();

    const body: IFeedbackData = {
      email: formData.email,
      problemDescription: formData.description,
      attachedImages: mediaStore.photosFeedback.map(p => p.preview),
    };

    FeedbackService.sendFeedbackData(body)
      .then(() => {
        appStore.showNotification('Ваш отзыв успешно отправлен\nразработчикам. Спасибо!', TypeOfNotification.Success);
        this.clearStore();
      })
      .catch(() => {
        appStore.showNotification(
          'При отправке произошла ошибка\nПопробуйте повторить отправку позже',
          TypeOfNotification.Error
        );
      })
      .finally(() => appStore.hideLoader());
  }
}

export default new FeedbackStore();
