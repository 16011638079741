import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  spinner: {
    margin: 'auto',
    animationDuration: '550ms',
  },
  progress: {
    color: theme.palette.secondary.main,
  },
}));

export default useStyles;
