import { makeStyles } from '@material-ui/core';
import { COLORS } from 'Shared/Constants/Colors';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(1.25),
    '& .MuiInput-root-37': {
      height: 40,
      width: 124,
    },
    '& .MuiInputBase-input-56': {
      paddingLeft: 10,
    },
    '& .MuiInputBase-root-188': {
      height: 40,
    },
    '& .MuiIconButton-root-70': {
      backgroundColor: 'rgba(255, 255, 255, 0.04) !important',
      paddingRight: 0,
    },
    '& .MuiInput-underline-40:hover:not(.Mui-disabled):before': {
      borderBottomColor: `${COLORS.BaseGreen} !important`,
    },
  },
  titleWrapper: {
    display: 'flex',
    marginRight: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
  },
  textField: {
    marginBottom: 0,
    justifyContent: 'flex-end',
    minWidth: 125,
    width: 138,
  },
}));

export default useStyles;
