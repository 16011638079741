import { action, makeObservable, observable } from 'mobx';

import { appStore, authStore } from 'Stores';

import { PhotoStorageLocation, TypeOfNotification } from 'Shared/Enums/enums';
import { IMedia, IPhoto } from 'Shared/Interfaces/Interfaces';

import { ALLOWED_FILE_TYPES, MAX_NUMBER_PHOTO_FILES, MAX_NUMBER_FEEDBACK_FILES } from 'Shared/Constants/Constants';
import Utils from 'Shared/Utils/Utils';

class MediaStore {
  public medias: IMedia[] = [];

  public photosFeedback: IPhoto[] = [];
  public photosErrors: string[] = [];

  constructor() {
    makeObservable(this, {
      medias: observable,
      photosFeedback: observable,
      photosErrors: observable,
      addToStorage: action,
      checkFile: action,
      checkLengthPhotos: action,
      showNotification: action,
      addPhoto: action,
      readingPhoto: action,
      addPreviewPhoto: action,
      removePhoto: action,
      clearPhotoError: action,
      clearFeedbackPhoto: action,
      clearStore: action,
    });
  }

  /**
   * добавление фото
   * @storage хранилище
   * @files  массив фото
   */
  public addPhoto(storage: PhotoStorageLocation, files: FileList) {
    appStore.hideNotification();
    this.clearPhotoError();
    for (let i = 0; i < files.length; i++) {
      const notValid = this.checkFile(files.item(i)!);
      if (!notValid) {
        const manyFiles = this.checkLengthPhotos(storage);
        if (manyFiles) return;

        const photo = {
          id: `cmr${(+new Date()).toString(16) + i} + ${i}`,
          photo: files.item(i)!,
          preview: '',
        };

        this.addToStorage(storage, photo);
        this.readingPhoto(storage, photo);
      }
    }
  }

  /**
   * добавление видео с камеры
   * @storage хранилище
   * @files  массив фото
   */
  public addCameraVideo(blobsArray: BlobPart[], preview: string) {
    // blobs_recorded refers to recorded blobs array
    const videoFile = new File(blobsArray, 'recording.webm', { type: 'video/webm' });

    appStore.hideNotification();
    this.clearPhotoError();

    const manyFiles = this.checkLengthPhotos(PhotoStorageLocation.Photos);
    if (manyFiles) return;

    const video: IMedia = {
      id: `cmr${(+new Date()).toString(16)}`,
      photo: videoFile,
      preview,
      isVideo: true,
    };

    return this.medias.push(video);
  }

  /**
   * добавление фото с камеры
   * @storage хранилище
   * @files  массив фото
   */
  public addCameraPhoto(photoData: string) {
    appStore.hideNotification();
    this.clearPhotoError();
    const manyFiles = this.checkLengthPhotos(PhotoStorageLocation.Photos);
    if (manyFiles) return;

    const photo = Utils.stringToFile(photoData);

    return this.medias.push(photo);
  }

  /**
   * Проверяем где хранить фото
   * @storage хранилище
   * @photo  фото
   */
  public addToStorage(storage: PhotoStorageLocation, photo: IPhoto) {
    switch (storage) {
      case PhotoStorageLocation.Photos:
        return this.medias.push({ id: photo.id, photo: photo.photo, isVideo: true, preview: '' });
      case PhotoStorageLocation.PhotoProfile:
        return authStore.changePhotoProfile(photo);
      case PhotoStorageLocation.PhotoFeedback:
        return this.photosFeedback.push(photo);
    }
  }

  /**
   * проверка файла
   * @file  фото
   */
  public checkFile(file: File) {
    const fileFormat = file.type.split('/')[1];
    const errorFileFormat = `Нельзя загрузить файлы формата: ${fileFormat}`;

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      this.showNotification(errorFileFormat);
      return true;
    }
  }

  /**
   * проверка количества загружаемых файлов
   * @storage хранилище
   */
  public checkLengthPhotos(storage: PhotoStorageLocation) {
    let currentStorage: IMedia[] | IPhoto[] = this.medias;
    let errorMaxLength = MAX_NUMBER_PHOTO_FILES;

    switch (storage) {
      case PhotoStorageLocation.Photos:
        currentStorage = this.medias;
        errorMaxLength = MAX_NUMBER_PHOTO_FILES;
        break;
      case PhotoStorageLocation.PhotoFeedback:
        currentStorage = this.photosFeedback;
        errorMaxLength = MAX_NUMBER_FEEDBACK_FILES;
        break;
      case PhotoStorageLocation.PhotoProfile:
        return false;
      default:
        return true;
    }

    if (currentStorage.length + 1 > errorMaxLength) {
      this.showNotification(`Макс. количество фото - ${errorMaxLength} шт.`);
      return true;
    }
  }

  /**
   * уведомление с ошибками загрузки файлов
   * @error ошибка
   */
  public showNotification(error: string) {
    const temp = this.photosErrors.includes(error);
    if (!temp) {
      this.photosErrors.push(error);
    }
    appStore.showNotification(`${this.photosErrors.join('\n')}`, TypeOfNotification.Warning);
  }

  /**
   * перевод фото в формат base64
   * @storage хранилище
   * @file фото
   */
  public async readingPhoto(storage: PhotoStorageLocation, file: IPhoto) {
    const reader = new FileReader();
    if (!file.photo) return;

    await reader.readAsDataURL(file.photo);
    reader.onloadend = () => {
      const photo = {
        id: file.id,
        photo: file.photo,
        preview: reader.result,
      };
      this.addPreviewPhoto(storage, photo);
    };
  }

  /**
   * добавление превью фото
   * @storage хранилище
   * @photo фото
   */
  public addPreviewPhoto(storage: PhotoStorageLocation, photo: IPhoto) {
    switch (storage) {
      case PhotoStorageLocation.Photos:
        return (this.medias = this.medias.map(item =>
          item.id === photo.id ? (item = { ...photo, isVideo: false }) : item
        ));

      case PhotoStorageLocation.PhotoProfile:
        return (authStore.photoProfile = photo);

      case PhotoStorageLocation.PhotoFeedback:
        return (this.photosFeedback = this.photosFeedback.map(item => (item.id === photo.id ? (item = photo) : item)));
    }
  }

  /**
   * удаление фото
   * @storage хранилище
   * @photoId id фото
   */
  public removePhoto(storage: PhotoStorageLocation, photoId: string) {
    switch (storage) {
      case PhotoStorageLocation.Photos:
        return (this.medias = this.medias.filter(item => item.id !== photoId));

      case PhotoStorageLocation.PhotoFeedback:
        return (this.photosFeedback = this.photosFeedback.filter(item => item.id !== photoId));
    }
    appStore.hideNotification();
  }

  /** * чистка ошибки загрузки файлов */
  public clearPhotoError() {
    this.photosErrors = [];
  }

  /** * чистка store */
  public clearStore() {
    this.medias = [];
  }

  /** * чистка фото для связи с разрабами */
  public clearFeedbackPhoto() {
    this.photosFeedback = [];
  }
}

export default new MediaStore();
