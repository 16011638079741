import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'Shared/Constants/Colors';

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '28px 0 10px 15px',
    marginBottom: 20,
    borderBottom: '2px solid #EEEEEE',

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${COLORS.BaseGreen}`,
    },
    '& .MuiInputBase-root': {
      color: `${COLORS.BaseGreen}`,
    },
    '@media (max-width: 506px)': {
      justifyContent: 'center',
    },
  },
  secondFilter: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  form: {
    minWidth: 120,
    marginRight: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.25),

    '@media (max-width: 665px)': {
      marginLeft: theme.spacing(1.1),
      marginRight: theme.spacing(3.5),
    },
  },
  label: {
    '&.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.common.black,
      opacity: '54%',
    },
    '&.MuiFormLabel-root': {
      paddingLeft: '10px',
    },
  },
  select: {
    height: 40,
    marginTop: '6px !important',
    borderBottom: `1px solid ${COLORS.BorderGray}`,
    '&::before': {
      borderBottom: 'none',
    },
    '&::after': {
      borderBottom: 'none',
    },
    '& div': {
      height: 30,
      paddingLeft: 10,
      paddingTop: 20,
      letterSpacing: -0.24,
      color: theme.palette.common.black,
      textAlign: 'left',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  buttonWrapperShow: {
    width: 150,
    paddingBottom: theme.spacing(1.25),

    '@media (max-width: 688px)': {
      width: 138,
    },

    '@media (max-width: 665px)': {
      width: 150,
    },
  },
}));

export default useStyles;
