import HTTPService from './HttpService';

import { BASE_PATH } from 'Shared/Constants/Constants';

class UserService extends HTTPService {
  constructor() {
    super(BASE_PATH.USER);
  }

  public getUserPhoto() {
    return this.GET<any>('/photo');
  }

  public changeUserPhoto(photo: string) {
    return this.PUT<any>('/photo', JSON.stringify(photo));
  }
}

export default new UserService();
