import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import { ButtonApp } from 'Components/Buttons/ButtonApp';

import { icons } from '../../Shared/Constants/icons';
import { DEFAULT_MAP_CENTER, Routes } from 'Shared/Constants/Constants';

import useStyles from './styles';

interface IProps {
  address: string;
  coordinates: { lat: number; lng: number };
  withMap: boolean;
}

const Coordinates = ({ address, coordinates, withMap }: IProps) => {
  const c = useStyles();

  const zoom = coordinates === DEFAULT_MAP_CENTER ? 12 : 16;

  return (
    <div className={c.coordinates}>
      <div className={c.coordinatesTitle}>
        <div>
          <div>
            <img src={icons.place.img} alt={icons.place.alt} />
            {address}
          </div>
          <span className={c.coordinatesValue}>{/* {coordinates.lat} N, {coordinates.lng} E */}</span>

          {!withMap && (
            <Link to={Routes.LOCATION} className={c.coordinatesView}>
              <ButtonApp className={c.buttonBack} endIcon={<div className={c.buttonArrow} />}>
                Смотреть на карте
              </ButtonApp>
            </Link>
          )}
        </div>

        {withMap && (
          <div className={c.mapContainer}>
            <MapContainer center={coordinates} zoom={zoom} scrollWheelZoom={false}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={coordinates} />
            </MapContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Coordinates);
