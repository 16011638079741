import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  coordinates: {
    width: '100%',
  },
  coordinatesTitle: {
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      fontFamily: theme.typography.fontFamily,
      fontSize: 15,
      letterSpacing: -0.24,
      color: theme.palette.common.black,
      textAlign: 'left',
    },
  },
  coordinatesValue: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    letterSpacing: -0.24,
    color: '#828282',
    textAlign: 'left',
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
  },
  buttonBack: {
    border: 'none',
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    boxShadow: 'none',
    fontSize: '15px',
    width: 'fit-content',
    padding: '0px',
    height: 'auto',
  },
  buttonArrow: {
    width: 10,
    height: 10,
    border: `2.5px solid ${theme.palette.primary.main}`,
    transform: 'rotate(45deg)',
    borderBottom: 'none',
    borderLeft: 'none',
  },
  coordinatesView: {
    '& button': {
      margin: '0',
    },
  },
  mapContainer: {
    overflow: 'hidden',
    width: '100%',
    height: '50vh',
  },
}));

export default useStyles;
