import { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

import { appStore, authStore } from 'Stores';

import { AgreementsPage, AuthPage, ClaimDetailPage, DashboardPage } from 'Modules';

import { Routes } from 'Shared/Constants/Constants';

const AuthRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    appStore.hideNotification();
  }, [location.pathname]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);

      const code = params.get('code');

      if (code) {
        authStore.authorizeUser(code);
      }
    }
  }, [location]);

  return (
    <>
      <Route path={Routes.COMMON.AUTH} component={AuthPage} exact={true} />
      <Route path={Routes.COMMON.DASHBOARD} component={DashboardPage} exact={true} />
      <Route path={Routes.COMMON.CLAIM_DETAIL} component={ClaimDetailPage} />
      <Route path={Routes.COMMON.AGREEMENTS} component={AgreementsPage} />
    </>
  );
};

export default AuthRoutes;
