import { observer } from 'mobx-react';

import { authStore } from 'Stores';

import { Link } from 'react-router-dom';

import { UserAvatar } from 'Components';
import useStyles from '../styles';
import { COLORS } from 'Shared/Constants/Colors';

const NotProfileHeader = () => {
  const c = useStyles({ color: COLORS.BaseRed });

  const userName = authStore.userData?.lastName || '';
  const userSecondName = authStore.userData?.firstName ? `${authStore.userData?.firstName[0]}.` : '';

  return (
    <Link to="/profile" className={c.toolbarLeft}>
      <UserAvatar spacing={3} />
      <p className={c.name}>{`${userName} ${userSecondName}`}</p>
    </Link>
  );
};

export default observer(NotProfileHeader);
