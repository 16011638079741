import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    height: 'unset',
    padding: theme.spacing(3),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    marginLeft: theme.spacing(2),
    '& > button': {
      '&:not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  active: {
    color: theme.palette.secondary.main,
  },
  filterPopover: {
    fontFamily: theme.typography.fontFamily,
  },
}));

export default useStyles;
