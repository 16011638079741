import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  photos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  photoPreview: {
    marginRight: '10px',
    marginBottom: '10px',
    width: 300,
    height: 300,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    letterSpacing: -0.24,
    color: theme.palette.common.black,
    textAlign: 'left',
  },
  videoIcon: {
    width: 20,
    fontSize: '5rem',
    position: 'absolute',
    bottom: -15,
    right: 12,
  },
}));

export default useStyles;
