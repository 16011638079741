import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useStyles } from './styles';

interface IProps {
  tabs: Array<{ label: string }>;
  value: number;
  handleChange: (e: any, newValue: number) => void;
}

const a11yProps = (index: any) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const TabsApp = (props: IProps) => {
  const { tabs, value, handleChange } = props;

  const c = useStyles();

  return (
    <AppBar position="fixed" className={c.appBar}>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} className={c.tab} {...a11yProps(index)} />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default TabsApp;
