import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  bonusBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
    '& span': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: 34,
      letterSpacing: 0.37,
      color: theme.palette.common.black,
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& p': {
      fontFamily: theme.typography.fontFamily,
      fontSize: 15,
      letterSpacing: -0.24,
      color: theme.palette.primary.main,
      marginLeft: 3,
    },
  },
}));

export default useStyles;
