import { LocalStorageItems, Method } from 'Shared/Enums/enums';

class HTTPService {
  private readonly basePath: string;
  private readonly host: string = (process.env.REACT_APP_HOST || window.location.origin) + '/api';

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  /**
   * запрос
   * @url - путь
   * @method - метод
   * @body - тело запроса
   */
  public async apiRequest(url: string, method: Method, body?: any, header?: any): Promise<any> {
    const options: any = { method, body };

    options.headers = header ? header : { 'Content-Type': 'application/json' };

    const token = window.localStorage.getItem(LocalStorageItems.Token);
    if (token) options.headers.Authorization = 'Bearer ' + token;

    return fetch(this.host + url, options).then(async response => {
      if (!response.ok) {
        throw await 'Что-то пошло не так...';
      }
      return response.json();
    });
  }

  public GET<T>(path: string): Promise<T> {
    return this.apiRequest(`${this.basePath}${path}`, Method.Get);
  }

  public POST<T>(path: string, body: any): Promise<T> {
    return this.apiRequest(`${this.basePath}${path}`, Method.Post, body);
  }

  public DELETE<T>(path: string): Promise<T> {
    return this.apiRequest(`${this.basePath}${path}`, Method.Delete);
  }

  public PUT<T>(path: string, body: any): Promise<T> {
    return this.apiRequest(`${this.basePath}${path}`, Method.Put, body);
  }

  public POST_FILE<T>(path: string, data: any): Promise<T> {
    return this.apiRequest(`${this.basePath}${path}`, Method.Post, data, { });
  }
}

export default HTTPService;
