import { useHistory } from 'react-router-dom';

import { appStore } from 'Stores';

import { ClaimTypes } from 'Shared/Enums/enums';
import { Routes } from 'Shared/Constants/Constants';
import { icons } from 'Shared/Constants/icons';

import useStyles from './styles';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

const ButtonsContainer = () => {
  const history = useHistory();

  const c = useStyles();

  const buttons = [
    {
      name: (
        <div className={c.buttonChildren}>
          <img src={icons.advertisement.img} alt={icons.reclame.alt} className={c.icon} />
          Реклама в неположенном месте
        </div>
      ),
      id: ClaimTypes.AdvertisingClaim,
    },
    {
      name: (
        <div className={c.buttonChildren}>
          <img src={icons.garbage.img} alt={icons.car.alt} className={c.icon} />
          Мусор в неположенном месте
        </div>
      ),
      id: ClaimTypes.RubbishClaim,
    },
    {
      name: (
        <div className={c.buttonChildren}>
          <img src={icons.car.img} alt={icons.car.alt} className={c.icon} />
          Парковка в неположенном месте
        </div>
      ),
      id: ClaimTypes.ParkingClaim,
    },
  ];

  const [appliedButtons] = useState(buttons.filter(b => process.env.REACT_APP_BUTTON_IDS?.includes(b.id.toString())));

  const [unImplementedButtons] = useState(
    buttons.filter(b => !process.env.REACT_APP_BUTTON_IDS?.includes(b.id.toString()))
  );

  const onClick = (type: ClaimTypes) => {
    appStore.changeRouteNumber(type);
    history.push(Routes.PHOTO);
  };

  return (
    <>
      {appliedButtons.map(item => {
        const buttonClickHandler = () => onClick(item.id);

        return <Button key={item.id} className={c.button} onClick={buttonClickHandler} children={item.name} />;
      })}

      <div className={c.containerText}>
        <p>Скоро появятся новые функции:</p>
      </div>

      {unImplementedButtons.map(item => (
        <Button disabled={true} key={item.id} className={clsx(c.button, c.grayButton)} children={item.name} />
      ))}
    </>
  );
};

export default observer(ButtonsContainer);
