import { observer } from 'mobx-react';
import { Redirect, useLocation } from 'react-router-dom';

import { authStore } from 'Stores';

import MainRoutes from './MainRoutes';

import { Routes } from 'Shared/Constants/Constants';

const PrivateRoutes = () => {
  const location = useLocation();

  const isCommonRoute = Object.values(Routes.COMMON).some(commonRoute => location.pathname.includes(commonRoute));

  return authStore.userData?.id ? (
    <MainRoutes />
  ) : isCommonRoute ? (
    <Redirect to={location.pathname + location.search} />
  ) : (
    <Redirect to={Routes.COMMON.AUTH} />
  );
};

export default observer(PrivateRoutes);
