import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import { Routes } from 'Shared/Constants/Constants';

import useStyles from './styles';

interface IProps {
  checked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const Label = () => {
  const c = useStyles();
  return (
    <p className={c.label}>
      Согласен с
      <Link className={c.link} to={Routes.COMMON.AGREEMENTS}>
        {' '}
        условиями политики конфиденциальности
      </Link>
    </p>
  );
};

export const AppCheckbox = (props: IProps) => {
  const { checked, handleChange } = props;

  const c = useStyles();

  return (
    <div className={c.root}>
      <FormControlLabel
        className={c.checkbox}
        control={<GreenCheckbox checked={checked} onChange={handleChange} name="policyCheckbox" />}
        label=""
      />
      <Label />
    </div>
  );
};
