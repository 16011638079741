import { useEffect, useState } from 'react';
import useStyles from './styles';

interface IProps {
  numberOfPhotos: number;
  tips: string[];
}

export const PhotoTips = (props: IProps) => {
  const { numberOfPhotos, tips } = props;
  const c = useStyles();

  const [localTips, setLocalTips] = useState(tips);

  useEffect(() => setLocalTips([...tips, 'Нажмите кнопку "Далее"']), [tips]);

  return (
    <div className={c.tipsBlock}>
      <pre className={c.photoTips}>{localTips[numberOfPhotos]}</pre>
    </div>
  );
};
