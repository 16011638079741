import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(theme => ({
  dashboardPage: {
    // animation: '$profilePage 0.5s forwards ease-out',
    marginBottom: 30,
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 17,
    textAlign: 'left',
    marginLeft: 15,
  },
  statisctic: {
    marginLeft: 15,
  },
  tableWrapper: {
    height: '100%',
  },
  columnStatus: {
    display: 'flex',
  },
  columnStatusIcon: {
    alignSelf: 'center',
    marginRight: '10px',
    '@media (max-width: 482px)': {
      display: 'none',
    },
    '@media (max-width: 418px)': {
      display: 'block',
    },
  },
  columnStatusLabel: {
    textAlign: 'start',

    '@media (max-width: 835px)': {
      display: 'none',
    },
    '@media (max-width: 728px)': {
      display: 'block',
    },
    '@media (max-width: 418px)': {
      display: 'none',
    },
  },
  imgStatus: {
    width: 15,
    height: 15,
  },
}));

export default useStyles;
