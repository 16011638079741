import { action, makeObservable, observable } from 'mobx';

import { appStore } from 'Stores';

import { UserService, EsiaService, ClaimService } from 'ApiServices';

import { IGetUserData, IGetUserClaimsArchive, IGetUserStatistics } from 'Shared/Interfaces/IGetDto';
import { IClaimsOfTheDay, IPhoto } from 'Shared/Interfaces/Interfaces';
import { LocalStorageItems, TypeOfNotification } from 'Shared/Enums/enums';

class AuthStore {
  public isLoading = false; // малый лоадер для архива заявок и авторизации
  public isLoadingAvatar = false; // малый лоадер для аватарки

  public userData: IGetUserData | null = null;
  public photoProfile: IPhoto | null = null;

  public userStatistics: IGetUserStatistics | null = null;
  public claimsArchivePage: IGetUserClaimsArchive | null = null;
  public claimsArchivePages: IClaimsOfTheDay[] = [];
  private page = 0;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isLoadingAvatar: observable,
      userData: observable,
      userStatistics: observable,
      claimsArchivePage: observable,
      claimsArchivePages: observable,
      photoProfile: observable,
      setIsLoadingAvatar: action,
      getUserPhoto: action,
      hideLoader: action,
      resetPage: action,
      changeUserStatistics: action,
      changeUserPhoto: action,
      changePhotoProfile: action,
      changeClaimsArchive: action,
      authorizeUser: action,
      getUserStatistics: action,
      getUserClaimsArchive: action,
      logOut: action,
      clearPhotoProfile: action,
    });
    const userAuthData = window.localStorage.getItem(LocalStorageItems.UserData);
    if (!userAuthData) this.userData = null;
    else {
      const userData: IGetUserData = JSON.parse(userAuthData);
      this.userData = userData;
    }
  }

  /** * изменение статистики пользователя */
  public changeUserStatistics(userStatistics: IGetUserStatistics) {
    this.userStatistics = userStatistics;
  }

  /** * изменение архива заявок пользователя
   * @claimsArchivePage - сохраняем текущую страницу со всей информацией об архиве
   * Кладем элементы текущей страницы (сам список нарушений на странице) в конец общего массива нарущений
   */
  public changeClaimsArchive(claimsArchivePage: IGetUserClaimsArchive) {
    this.claimsArchivePage = claimsArchivePage;
    this.claimsArchivePages = this.claimsArchivePages.concat(this.claimsArchivePage.items);
  }

  public hideLoader() {
    this.isLoading = false;
    this.isLoadingAvatar = false;
  }

  public setIsLoadingAvatar(value: boolean) {
    this.isLoadingAvatar = value;
  }

  /** * сбрасываем все текщие переменные по архиву заявок, чтобы  при открытии списка
   * всегда показывалась первая страница
   */
  public resetPage() {
    this.page = 0;
    this.claimsArchivePages = [];
    this.claimsArchivePage = null;
  }

  /** * получение статистики заявок пользователя с сервера */
  public getUserStatistics() {
    if (!this.userData?.id) return;

    appStore.showLoader();
    ClaimService.getUserStatistics(this.userData.id)
      .then(response => this.changeUserStatistics(response))
      .catch(err => console.log(err))
      .finally(() => appStore.hideLoader());
  }

  /** * получение архива заявок пользователя с сервера */
  public getUserClaimsArchive() {
    if (!this.userData?.id) return;

    if (this.claimsArchivePage?.totalPages === this.page || this.claimsArchivePage?.currentPage! < this.page) {
      return;
    }

    this.page++;

    this.isLoading = true;
    ClaimService.getUserClaims(this.userData.id, this.page)
      .then(response => this.changeClaimsArchive(response))
      .catch(err => console.log(err))
      .finally(() => this.hideLoader());
  }

  /** * получение фото пользователя */
  public async getUserPhoto() {
    this.isLoadingAvatar = true;
    try {
      const response = await UserService.getUserPhoto();
      if (response) this.changePhotoProfile({ id: '0', preview: response.base64String, photo: null });
    } catch (err) {
      console.log(err);
    } finally {
      this.setIsLoadingAvatar(false);
    }
  }

  public changePhotoProfile(photo: IPhoto) {
    this.photoProfile = photo;
  }

  /** * изменение фото пользователя */
  public changeUserPhoto(photo: string) {
    this.isLoadingAvatar = true;
    UserService.changeUserPhoto(photo)
      .then(response => {
        if (response.photo) this.photoProfile = { id: response.id.toString(), preview: response.photo, photo: null };
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.setIsLoadingAvatar(false);
      });
  }

  /** * запрашиваем ссылку на авторизацию в ЕСИА с бэка */
  public async getAuthorizeLink() {
    try {
      const dto = await EsiaService.getAuthorizeLink();
      localStorage.setItem(LocalStorageItems.EsiaState, dto.state);
      return dto.link;
    } catch (err) {
      console.log(err);
      appStore.showNotification('Ошибка соединения с сервером. Попробуйте позже', TypeOfNotification.Error);
      return '';
    }
  }

  /** * Авторизовать пользователя, передаём код после авторизации в ЕСИА на бэк, получаем данные пользователя */
  public async authorizeUser(code: string) {
    appStore.showLoader();
    try {
      const dto = await EsiaService.getAuthUser(code);
      this.userData = dto;
      if (dto.photo) this.photoProfile = { id: dto.id.toString(), preview: dto.photo, photo: null };
      localStorage.setItem(LocalStorageItems.UserData, JSON.stringify(this.userData));
      localStorage.setItem(LocalStorageItems.Token, dto.accessToken);
    } catch (err) {
      console.log(err);
    } finally {
      appStore.hideLoader();
    }
  }

  /** * выйти из профиля пользователя */
  public logOut() {
    localStorage.setItem(LocalStorageItems.UserData, '');
    localStorage.setItem(LocalStorageItems.Token, '');
    this.userData = null;
  }

  /** * чистка photoProfile */
  public clearPhotoProfile() {
    this.photoProfile = null;
  }
}

export default new AuthStore();
