import { makeStyles } from '@material-ui/core';
import { COLORS } from 'Shared/Constants/Colors';

const useStyles = makeStyles(theme => ({
  statisticBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 30,

    '@media (max-width: 534px)': {
      justifyContent: 'center',
    },
  },
  statisticItemWrapper: {
    width: 166,
    height: 65,
    background: '#F7F7F7',
    borderRadius: '4px',
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    marginBottom: 10,
    marginRight: 10,

    '&:nth-child(2), &:nth-child(5), &:nth-child(6)': {
      color: COLORS.BaseGreen,
    },
    '&:nth-child(3)': {
      color: theme.palette.primary.main,
    },
    '&:nth-child(4)': {
      color: COLORS.BaseBlue,
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& span': {
      fontWeight: 600,
      marginBottom: 5,
    },
    '& p': {
      fontWeight: 400,
      fontSize: 13,
      marginLeft: 3,
      '@media (max-width: 534px)': {
        fontSize: 12,
      },
      '@media (max-width: 526px)': {
        fontSize: 12,
      },
      '@media (max-width: 370px)': {
        marginLeft: 0,
      },
    },
    '@media (max-width: 534px)': {
      fontSize: 14,
    },
    '@media (max-width: 526px)': {
      width: 146,
    },
    '@media (max-width: 506px)': {
      width: 156,
      height: 50,
    },
    '@media (max-width: 364px)': {
      width: 140,
    },
    '@media (max-width: 335px)': {
      width: 140,
    },
  },
  statisticItem: {
    marginTop: 12,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',

    '@media (max-width: 506px)': {
      marginTop: 6,
    },
  },
}));

export default useStyles;
