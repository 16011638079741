import { ReactNode, useCallback } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { appStore } from 'Stores';

import { Button } from '@material-ui/core';
import ProfileHeader from 'Components/Header/Components/ProfileHeader';

import useStyles from './styles';
import { COLORS } from 'Shared/Constants/Colors';

interface IProps {
  to: string;
  title: string;
  children: ReactNode;
  className?: string;
  disabledButton?: boolean;
  withNextButton?: boolean;
  onBack?: () => void;
}

export const WrapperPage = (props: IProps) => {
  const { title, to, children, className, disabledButton = false, withNextButton = true, onBack } = props;

  const c = useStyles();
  const history = useHistory();

  const onClick = useCallback(() => {
    appStore.hideNotification();
    history.push(to);
  }, [history, to]);

  return (
    <div className={clsx(c.wrapper, className)}>
      <div className={c.wrapperHeader}>
        <ProfileHeader className={c.backButton} styleOptions={{ color: COLORS.White }} onBack={onBack} />
        <p>{title}</p>
      </div>

      {children}

      <div className={c.wrapperFooter}>
        {withNextButton && (
          <Button className={c.wrapperFooterButton} disabled={disabledButton} onClick={onClick}>
            Далее
          </Button>
        )}
      </div>
    </div>
  );
};
