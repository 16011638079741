import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Container } from '@material-ui/core';

import { authStore, claimsStore } from 'Stores';

import { Header } from 'Components';
import { ClaimsArchive, Links, ProfileInfo } from './Components';

import useStyles from './styles';
import Utils from 'Shared/Utils/Utils';
import { Statistic } from 'Modules/DashboardPage/Components';

export interface IStyles {
  transform: string;
  height: number;
  opacity: number;
}

const ProfilePage = () => {
  const statistics = authStore.userStatistics;

  const endOfWord = Utils.getTheEndOfAWord(statistics?.pendingCount);

  const [open, setOpen] = useState(true);

  const propsStyles: IStyles = {
    transform: open ? 'rotate(45deg)' : 'rotate(-135deg)',
    height: open ? 0 : 300,
    opacity: open ? 0 : 1,
  };

  const c = useStyles(propsStyles);

  useEffect(() => {
    authStore.getUserStatistics();
  }, []);

  const openArchive = () => {
    if (open) {
      authStore.resetPage();
      authStore.getUserClaimsArchive();
    }
    setOpen(!open);
  };

  const logOutProfile = () => {
    authStore.logOut();
    claimsStore.clearStore();
  };

  return (
    <div className={c.profilePage}>
      <Header isProfile={true} settings={false} />

      <Container className={c.profileContainer}>
        <ProfileInfo />
        <div className={c.logOut} onClick={logOutProfile}>
          Выйти
        </div>

        <div className={c.statistic}>
          <p className={c.title}>Мои уведомления </p>
          <Statistic statistics={authStore.userStatistics} />
          <p className={c.review}>
            {statistics?.pendingCount ? `${statistics?.pendingCount} уведомлен${endOfWord} на рассмотрении` : ''}
          </p>
        </div>

        <div className={c.statistic}>
          <div className={c.titleBlock} onClick={openArchive}>
            <p className={c.title}>История</p>
            <span />
          </div>
          <ClaimsArchive className={c.body} />
        </div>

        {/*TODO В дальнейшем будет использоваться
				 <div className={c.statistic}>
					<p className={c.title}>Начислено бонусов</p>
					<Bonus/>
				</div>

				<div className={c.statistic}>
					<p className={c.title}>Рейтинг</p>
					<Rating/>
				</div> */}

        <Links />
      </Container>
    </div>
  );
};

export default observer(ProfilePage);
