import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FormControl, Input, MenuItem, Select } from '@material-ui/core';

import { appStore } from 'Stores';

import useStyles from './styles';

const SelectionViolation = () => {
  const c = useStyles();

  const reportTypeOffenses = appStore.reportTypeOffenses;

  const [attentionName, setAttentionName] = useState([reportTypeOffenses![0].name]);

  useEffect(() => {
    appStore.getOffensesId(attentionName);
  }, [attentionName]);

  const handleChange = (event: any) => {
    appStore.setOffensesId(event.target.key);
    setAttentionName(event.target.value);
  }

  return (
    <FormControl className={c.form}>
      <Select value={attentionName} onChange={handleChange} input={<Input />} className={c.select}>
        {reportTypeOffenses?.map(item => (
          <MenuItem key={item.id} value={item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default observer(SelectionViolation);
