import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  wrapperHeader: {
    width: '100%',
    height: 44,
    color: theme.palette.common.white,
    background: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: 22,
      letterSpacing: -0.41,

      '@media (max-width: 355px)': {
        fontSize: 18,
      },
    },
  },
  wrapperFooter: {
    position: 'fixed',
    zIndex: 1000,
    bottom: 0,
    width: '100%',
    height: 50,
    background: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  wrapperFooterButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: -0.41,
    color: theme.palette.common.white,
    background: 'transparent',
    marginRight: 16,
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
    },
    '&:disabled': {
      color: 'rgb(247 247 247 / 28%)',
    },
  },
  backButton: {
    background: 'black',
    position: 'absolute',
    left: 11,
  },
}));

export default useStyles;
