import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  profileInfo: {
    marginTop: 25,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
  },
  largeAvatar: {
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    border: `1.5px solid ${theme.palette.primary.main}`,
    '& img': {
      width: 'auto',
    },
  },
  containerName: {
    marginLeft: 15,
  },
  name: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: '-0.41px',
    color: theme.palette.common.black,
    marginBottom: 5,
  },
  rootPhoto: {
    position: 'relative',
    borderRadius: '50%',
  },
  containerAvatar: {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      borderRadius: '50%',
      zIndex: 1,
      opacity: 0,
    },
    '&:hover': {
      '&::after': {
        opacity: 1,
        transition: 'all 300ms',
      },
      '& label span': {
        opacity: 1,
        transition: 'all 300ms',
        zIndex: 2,
      },
    },
  },
  uploadInput: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    background: 'transparent',
    color: theme.palette.common.white,
    opacity: 0,
    zIndex: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  spinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.6)',
  },
}));

export default useStyles;
