import { action, makeObservable, observable } from 'mobx';

export class PhoneModel {
  public phone: string = '';
  public isValid: boolean = true;

  constructor() {
    makeObservable(this, {
      phone: observable,
      isValid: observable,

      validate: action,
      changePhone: action,
    });
  }

  public validate() {
    const phone = this.phone;
    this.isValid = phone.length === 11 || phone.length === 10 || phone.length === 6;
  }

  public changePhone(value: string) {
    this.phone = value;
    this.isValid = true;
  }
}
