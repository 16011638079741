import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import useStyles, { IButtonStyles } from './styles';

interface IProps {
  startIcon?: JSX.Element | null;
  endIcon?: JSX.Element | null;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void | undefined | Promise<void>;
  className?: string;
}

type TargetPropsType = IProps & IButtonStyles;

export const ButtonApp = (props: TargetPropsType) => {
  const { startIcon = null, endIcon = null, onClick, className = '', children, disabled = false, ...rest } = props;

  const c = useStyles(rest);

  return (
    <Button
      className={clsx(c.buttonApp, className)}
      startIcon={startIcon}
      onClick={onClick}
      endIcon={endIcon}
      disabled={disabled}>
      {children}
    </Button>
  );
};
