import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
  },
  select: {
    width: 'inherit',
    height: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    '&::before': {
      borderBottom: 'none',
    },
    '&::after': {
      borderBottom: 'none',
    },
    '& div': {
      height: 30,
      paddingLeft: 10,
      paddingTop: 20,
      fontFamily: theme.typography.fontFamily,
      fontSize: 15,
      letterSpacing: -0.24,
      color: theme.palette.common.black,
      textAlign: 'left',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& svg': {
      color: 'rgba(0, 0, 0, 1)',
    },
  },
}));

export default useStyles;
