import { observer } from 'mobx-react';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

interface IProps {
  className?: string;
  multiple?: boolean;
  disabled?: boolean;
  icon: JSX.Element;
  handleChange?: (files: FileList) => void;
}

const UploadPhotos = (props: IProps) => {
  const { className, multiple = true, disabled = false, icon, handleChange } = props;
  const c = useStyles();

  const onChange = (e: any) => {
    if (handleChange) handleChange(e.target.files);
  };

  return (
    <>
      <input
        multiple={multiple}
        disabled={disabled}
        accept="image/*"
        className={c.input}
        id="icon-button-file"
        type="file"
        onChange={onChange}
        onClick={(e: any) => (e.target.value = null)}
      />

      <label htmlFor="icon-button-file">
        <IconButton
          aria-label="upload picture"
          component="span"
          className={clsx(c.buttonUploadPicture, className)}
          disabled={disabled}>
          {icon}
        </IconButton>
      </label>
    </>
  );
};

export default observer(UploadPhotos);
