import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mainPage: {
    display: 'flex',
    flexDirection: 'column',
    // animation: '$mainPage 0.5s forwards ease-out',
  },
  '@keyframes mainPage': {
    '0%': {
      opacity: 0,
      marginTop: '-100px',
    },
    '100%': {
      opacity: 1,
      marginTop: '0px',
    },
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    fontSize: '12px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#414042',
    marginBottom: 10,
    width: '100%',
    fontFamily: theme.typography.fontFamily,
  },
  icon: {
    width: 14,
    marginLeft: 4,
    marginTop: -4,
  },
}));

export default useStyles;
