import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PhotoCamera } from '@material-ui/icons';
import clsx from 'clsx';

import { authStore, mediaStore } from 'Stores';

import { PhotoStorageLocation } from 'Shared/Enums/enums';

import { Spinner, UploadPhotos, UserAvatar } from 'Components';

import useStyles from './styles';

const ProfileInfo = () => {
  const c = useStyles();

  const userName = authStore.userData?.firstName || '';
  const userSecondName = authStore.userData?.lastName || '';
  const avatar = authStore.photoProfile;
  const isLoading = authStore.isLoadingAvatar;

  const [open, setOpen] = useState(false);

  const menuStyle = clsx({
    [c.rootPhoto]: true,
    [c.containerAvatar]: open,
  });

  useEffect(() => {
    if (!avatar?.preview || !open) return;

    authStore.changeUserPhoto(avatar?.preview);
    setOpen(false);
  }, [avatar?.preview]);

  const onClick = () => setOpen(true);

  const onPhotoLoad = (files: FileList) => {
    mediaStore.addPhoto(PhotoStorageLocation.PhotoProfile, files);
  };

  return (
    <div className={c.profileInfo}>
      <div className={menuStyle} onClick={onClick}>
        <UserAvatar spacing={7.5} />

        <UploadPhotos className={c.uploadInput} multiple={false} handleChange={onPhotoLoad} icon={<PhotoCamera />} />

        {isLoading && <Spinner size={20} className={c.spinner} />}
      </div>

      <div className={c.containerName}>
        <p className={c.name}>{`${userSecondName} ${userName}`}</p>
        {/* TODO В дальнейшем будет использоваться				
				<UserRating/> */}
      </div>
    </div>
  );
};

export default observer(ProfileInfo);
