import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
  },
  titleBlock: {
    marginBottom: theme.spacing(1),
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 700,
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(6),
  },
  text: {
    textAlign: 'justify',
    textIndent: '2em',
    lineHeight: 1.1,
  },
  bold: {
    fontWeight: 700,
  },
  update: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
