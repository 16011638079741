import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  carNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  carNumberBlock: {
    background: theme.palette.common.white,
    border: '2.28426px solid #000000',
    boxShadow: `0px 0px 0px 0.856599px ${theme.palette.common.white}`,
    borderRadius: 6.85279,
    width: 230,
    height: 47.97,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  numberInput: {
    width: 170,
    borderTopLeftRadius: 6.85279,
    borderBottomLeftRadius: 6.85279,
    height: '100%',
    borderRight: '1.71px solid  #000000',
    fontFamily: 'RoadNumbers',
    fontSize: 52.5381,
    color: theme.palette.common.black,
    padding: '0px 0px 10px 10px',
    letterSpacing: '0.07em',
  },
  codeBlock: {},
  flag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& p': {
      fontFamily: 'SF Compact Rounded',
      fontSize: 11.4213,
      letterSpacing: '0.12em',
      color: theme.palette.common.black,
    },
  },
  regionCode: {
    width: 54,
    height: 30,
    borderTopRightRadius: 6.85279,
    borderBottomRightRadius: 6.85279,
    fontFamily: 'RoadNumbers',
    fontSize: 37.1193,
    color: theme.palette.common.black,
    padding: '2px 0px 10px 4px',
  },
}));

export default useStyles;
