import HTTPService from './HttpService';

import { IFeedbackData } from 'Shared/Interfaces/Interfaces';
import { BASE_PATH } from 'Shared/Constants/Constants';

class FeedbackService extends HTTPService {
  constructor() {
    super(BASE_PATH.FEEDBACK);
  }

  public async sendFeedbackData(data: IFeedbackData) {
    return this.POST('', JSON.stringify(data));
  }
}

export default new FeedbackService();
