export const DEFAULT_MAP_CENTER = { lat: 56.46, lng: 84.95 };

/** разрешенные типы файлов */
export const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];

/** максимальное количество фото правонарушений, которое можно загрузить */
export const MAX_NUMBER_PHOTO_FILES = 2;
/** максимальное количество фото для фидбэка, которое можно загрузить */
export const MAX_NUMBER_FEEDBACK_FILES = 5;

/** Минимальное/максимальное кол-во номеров у организации */
export const MIN_PHONE_NUMBERS_COUNT = 1;
export const MAX_PHONE_NUMBERS_COUNT = 8;

export const Routes = {
  COMMON: {
    AUTH: '/sign-in',
    CLAIM_DETAIL: '/claim-detail',
    DASHBOARD: '/dashboard',
    AGREEMENTS: '/user-agreement',
  },
  MAIN: '/',
  PROFILE: '/profile',
  PHOTO: '/add-photo',
  LOCATION: '/location',
  DIALOG: '/dialog',
  FEEDBACK: '/feedback',
  SUCCESSFUL: '/successful-submission',
};

/** данные для запроса к стороннему серверу	для получения адреса. */
export const LOCATION_REQUEST_DATA = {
  URL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address/',
  API_KEY: 'deef867f1fbb6f9d2c2742e262d68bb57c2bd2d8',
};

/** базовый URL. */
export const BASE_PATH = {
  ADVERTISING: '/advertising-reports',
  FEEDBACK: '/appeals',
  ESIA: '/esia',
  OFFENSE: '/offences',
  USER: '/user',
  PARKING: '/parking-reports',
  TRASH: '/rubbish-reports',
  CLAIM: '/reports',
  VIDEO: '/videos',
};

/** количество элементов на странице для пагинации. */
export const NUMBER_OF_ITEMS = 5;
