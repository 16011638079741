import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    overflow: 'hidden',
    backgroundColor: 'black !important',
  },
  camera: {
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  photoBtn: {
    minWidth: 60,
    height: 60,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.common.white}`,
    position: 'absolute',
    bottom: 26,
    left: '50%',
    zIndex: 10000,
    transform: 'translate(-50%,-50%)',
  },
  photoCircle: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'absolute',
    backgroundColor: 'white !important',
    borderRadius: '50%',
    border: `3px solid white`,
    height: 50,
    width: 50,
    '&:active': {
      transform: 'scale(0.9) translate(-55%,-55%)',
    },
  },
  videoBtn: {
    minWidth: 60,
    height: 60,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.common.white}`,
    position: 'absolute',
    bottom: 26,
    left: '50%',
    zIndex: 10000,
    transform: 'translate(-50%,-50%)',
  },
  videoCircle: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'absolute',
    border: `3px solid red`,
    borderRadius: '10%',
    backgroundColor: 'red !important',
    height: 25,
    width: 25,
  },
  blackScreen: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    zIndex: 3,
  },
  canvasBlock: {
    position: 'absolute',
    maxWidth: '100%',
    maxHeight: '100%',
    zIndex: 2,
    height: 'fit-content',
    width: 'fit-content',
  },
  videoBlock: {
    position: 'absolute',
    top: 44,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    transition: 'all 1s',
  },
  photoPreviewBlock: {
    position: 'absolute',
    top: 42,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 4,
  },
  cameraPreviewBlock: {
    position: 'absolute',
    top: 42,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    zIndex: 4,
  },
  iconAttach: {
    transform: 'rotate(-45deg)',
  },
  wrapperFooter: {
    position: 'fixed',
    zIndex: 10000,
    bottom: 0,
    width: '100%',
    height: 45,
    background: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  wrapperFooterButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: -0.41,
    color: theme.palette.common.white,
    background: 'transparent',
    marginRight: 16,
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
    },
    '&:disabled': {
      color: 'rgb(247 247 247 / 28%)',
    },
    '&:first-child': {
      position: 'fixed',
      left: 5,
    },
  },
  uploadPhotos: {
    position: 'fixed',
    bottom: 5,
    left: 7,
    zIndex: 10000,
  },
}));

export default useStyles;
