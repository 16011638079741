import { observer } from 'mobx-react';

import { IGetUserStatistics } from 'Shared/Interfaces/IGetDto';

import {
  AccessTime as AccessTimeIcon,
  Close as RejectIcon,
  Done as DoneIcon,
  EmojiEvents as EmojiEventsIcon,
  MenuBook as MenuBookIcon
} from '@material-ui/icons';

import { COLORS } from 'Shared/Constants/Colors';
import useStyles from './styles';
import clsx from 'clsx';
import { ITotalStatistics } from 'Shared/Interfaces/ITotalStatisticDto';

const Statistic = (props: { className?: string; statistics: IGetUserStatistics | ITotalStatistics | null }) => {
  const c = useStyles();

  const items = [
    {
      id: 1,
      title: 'Подано уведомлений',
      value: props.statistics?.totalCount || 0,
      image: null,
    },
    {
      id: 2,
      title: 'Принято',
      value: props.statistics?.acceptedCount || 0,
      image: <DoneIcon color="secondary" />,
    },
    {
      id: 3,
      title: 'Отклонено',
      value: props.statistics?.declinedCount || 0,
      image: <RejectIcon />,
    },
    {
      id: 4,
      title: 'Ожидает обработки',
      value: props.statistics?.pendingCount || 0,
      image: <AccessTimeIcon style={{ color: COLORS.BaseBlue }} />,
    },
    {
      id: 5,
      title: 'Зарегистрировано',
      value: props.statistics?.registeredCount || 0,
      image: <EmojiEventsIcon color="secondary" />,
    },
    {
      id: 6,
      title: 'Работа завершена',
      value: props.statistics?.finishedCount || 0,
      image: <MenuBookIcon color="secondary" />,
    },
  ];

  return (
    <div className={clsx(c.statisticBlock, props.className)}>
      {items.map(item => (
        <div key={item.id} className={c.statisticItemWrapper}>
          <div className={c.statisticItem}>
            <span>{item.value}</span>
            <div>
              {item.image}
              <p>{item.title}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default observer(Statistic);
