import useStyles from './styles';

const TextContainer = () => {
  const c = useStyles();

  return (
    <div className={c.containerText}>
      <h1>Что не так?</h1>
    </div>
  );
};

export default TextContainer;
