import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  playPause: {
    width: 74,
    height: 74,
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: '37px 0 37px 74px',
    borderColor: 'transparent transparent transparent white',
    backgroundColor: 'transparent',
    display: 'block',
    top: '50%',
    left: '50%',
    margin: '0 auto',
    position: 'absolute',
    zIndex: 10,

    // '&:active': {
    //   backgroundColor: 'transparent',
    // },
  },
}));

export default useStyles;
