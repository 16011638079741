import { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import { fullImageStore } from 'Stores';

import { ArrowForwardIos as NextIcon, ArrowBackIos as PrevIcon } from '@material-ui/icons';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ImageEditorPanel from './ImageEditorPanel';

import useStyles from './styles';
import { IPhotoView } from 'Shared/Interfaces/Interfaces';
import { KeyCodes } from 'Shared/Constants/KeyCodes';

interface IProps {
  photos: IPhotoView[];
}

const SliderArrow = (props: { onClick: () => void; children: JSX.Element; isNext: boolean }) => {
  const c = useStyles();

  return (
    <div onClick={props.onClick} className={clsx(c.arrowIconWrapper, props.isNext ? c.arrowNext : c.arrowBack)}>
      {props.children}
    </div>
  );
};

const FullScreenImage = (props: IProps) => {
  const c = useStyles();
  const { photos } = props;

  const handleKeyUp = (e: any) => {
    if (e.keyCode === KeyCodes.LEFT) handlePrevImage();
    else if (e.keyCode === KeyCodes.RIGHT) handleNextImage();
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrevImage = () => {
    fullImageStore.changeSelectedImage(photos, false);
  };

  const handleNextImage = () => {
    fullImageStore.changeSelectedImage(photos, true);
  };

  const onZoomStop = useCallback(transform => {
    setTimeout(() => {
      const container = transform?.instance?.contentComponent;
      if (container) {
        container.style.transform = container.style.transform
          .replace('translate3d', 'translate')
          .replace(', 0px)', ')');
      }
    }, 300);
  }, []);

  return (
    <div
      className={c.fullScreenImageWrapper}
      style={{ visibility: fullImageStore.selectedImageIndex ? 'visible' : 'hidden' }}>
      {fullImageStore.selectedImageIndex && (
        <TransformWrapper initialScale={0.9} minScale={0.7} onZoomStop={onZoomStop} onPanningStop={onZoomStop}>
          {({ zoomIn, zoomOut, resetTransform }) => {
            return (
              <>
                <ImageEditorPanel zoomIn={zoomIn} zoomOut={zoomOut} resetTransform={resetTransform} />

                {fullImageStore.selectedImageIndex && fullImageStore.selectedImageIndex > 1 && (
                  <SliderArrow children={<PrevIcon />} isNext={false} onClick={handlePrevImage} />
                )}
                <TransformComponent>
                  <img src={photos[(fullImageStore.selectedImageIndex ?? 1) - 1]?.photo ?? ''} alt='' />
                </TransformComponent>
                {fullImageStore.selectedImageIndex && fullImageStore.selectedImageIndex <= photos.length - 1 && (
                  <SliderArrow children={<NextIcon />} isNext={true} onClick={handleNextImage} />
                )}
              </>
            );
          }}
        </TransformWrapper>
      )}
    </div>
  );
};

export default observer(FullScreenImage);
