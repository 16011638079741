import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';

import { appStore } from 'Stores';

import { Notification, Spinner } from 'Components';

import { AuthRoutes, PrivateRoutes } from 'Routes';
import { theme } from 'theme';
import './App.css';

const useStyles = makeStyles(theme => ({
  spinner: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.6)',
    zIndex: 999,
  },
}));

const App = () => {
  const c = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          {appStore.isLoading && <Spinner size={40} className={c.spinner} />}

          <Notification />
          <Switch>
            <>
              <AuthRoutes />
              <PrivateRoutes />
            </>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default observer(App);
