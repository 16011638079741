import { observer } from 'mobx-react';

import { authStore } from 'Stores';

import { Spinner } from 'Components';
import { ArchivePage } from './Components';

import { IClaim, IClaimsOfTheDay } from 'Shared/Interfaces/Interfaces';
import { ClaimStatuses, ClaimTypes } from 'Shared/Enums/enums';

import { icons } from 'Shared/Constants/icons';
import {
  AccessTime as AccessTimeIcon,
  Close as RejectIcon,
  Done as DoneIcon,
  DeleteOutline as DeleteOutlineIcon,
  EmojiEvents as EmojiEventsIcon,
  MenuBook as MenuBookIcon,
} from '@material-ui/icons';

import useStyles from './styles';

interface IProps {
  className: string;
}
interface IIcon {
  img: string;
  alt: string;
}

const ClaimsArchive = (props: IProps) => {
  const { className } = props;

  const data: IClaimsOfTheDay[] = authStore.claimsArchivePages;
  const isLoading = authStore.isLoading;

  const c = useStyles();

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && authStore.claimsArchivePage) {
      authStore.getUserClaimsArchive();
    }
  };

  const createImage = (icon: IIcon, styles: any) => <img className={styles} src={icon.img} alt={icon.alt} />;

  const addPictureForStatuses = (claimStatus: ClaimStatuses) => {
    switch (claimStatus) {
      case ClaimStatuses.Pending:
        return <AccessTimeIcon className={c.imgStatus} />; // временная иконка
      case ClaimStatuses.Declined:
        return <RejectIcon className={c.imgStatus} />;
      case ClaimStatuses.Accepted:
        return <DoneIcon className={c.imgStatus} color="secondary" />;
      case ClaimStatuses.Finished:
        return <EmojiEventsIcon className={c.imgStatus} color="secondary" />;
      case ClaimStatuses.Registered:
        return <MenuBookIcon className={c.imgStatus} color="secondary" />;
    }
  };

  const addPictureForTypes = (claimType: ClaimTypes) => {
    switch (claimType) {
      case ClaimTypes.ParkingClaim:
        return createImage(icons.car, c.imgType);
      case ClaimTypes.AdvertisingClaim:
        return createImage(icons.reclame, c.imgType);
      case ClaimTypes.RubbishClaim:
        return <DeleteOutlineIcon />; // временная иконка
    }
  };

  const changeCols = (cols: IClaim[]) => {
    return cols.map((column: IClaim) => ({
      ...column,
      claimTypeIcon: addPictureForTypes(column.reportType),
      claimStatusIcon: addPictureForStatuses(column.status),
    }));
  };

  return (
    <div className={className} onScroll={handleScroll}>
      {data?.map((item: IClaimsOfTheDay, i: number) => {
        const pageElements = changeCols(item.reports);
        return <ArchivePage key={i} title={item.date} pageElements={pageElements} />;
      })}

      {isLoading && <Spinner />}
    </div>
  );
};

export default observer(ClaimsArchive);
