import { useEffect } from 'react';
import { Container } from '@material-ui/core';
import { observer } from 'mobx-react';

import { claimsStore } from 'Stores';

import { TextContainer, ButtonsContainer } from './Components';

import { Header } from 'Components';

import useStyles from './styles';
import { icons } from 'Shared/Constants/icons';

const MainPage = () => {
  const c = useStyles();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      pos => {
        const position = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };

        claimsStore.getPosition(position);
      },
      () => console.log('Пожалуйста, разрешите доступ к использованию Вашей геопозиции!')
    );
  }, []);

  return (
    <div className={c.mainPage}>
      <Header settings={false} />

      <Container>
        <TextContainer />
        <ButtonsContainer />
      </Container>

      <p className={c.footer}>
        С заботой о городе, Rubius <img src={icons.rubiusLogo.img} alt={icons.rubiusLogo.alt} className={c.icon} />
      </p>
    </div>
  );
};

export default observer(MainPage);
