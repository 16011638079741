import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { authStore } from 'Stores';

import { ButtonApp } from 'Components';

import { AppCheckbox } from '../Checkbox/AppCheckbox';

import { useStyles } from './styles';
import { icons } from 'Shared/Constants/icons';

const AuthControl = () => {
  const [checked, setChecked] = useState(false);

  const c = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setChecked(e.target.checked);

  const onClick = async () => {
    const link = await authStore.getAuthorizeLink();
    if (link) window.location.assign(link);
  };

  return (
    <>
      <div className={c.control}>
        <p>Войдите через Госуслуги</p>

        <ButtonApp disabled={checked ? false : true} onClick={onClick}>
          Войти
        </ButtonApp>
      </div>

      <AppCheckbox checked={checked} handleChange={handleChange} />

      <p className={c.footer}>
        С заботой о городе, Rubius <img src={icons.rubiusLogo.img} alt={icons.rubiusLogo.alt} className={c.icon} />
      </p>
    </>
  );
};

export default observer(AuthControl);
