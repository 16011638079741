export const icons = {
  logotype: {
    img: '../../icons/logotype.png',
    alt: 'logotype',
  },
  settings: {
    img: '../../icons/setings.svg',
    alt: 'setings',
  },
  ratingCool: {
    img: '../../icons/cool.png',
    alt: 'cool',
  },
  ratingStar: {
    img: '../../icons/star.png',
    alt: 'star',
  },
  info: {
    img: '../../icons/info.png',
    alt: 'info',
  },
  car: {
    img: '../../icons/car.svg',
    alt: 'car',
  },
  reclame: {
    img: '../../icons/reclame.svg',
    alt: 'reclame',
  },
  advertisement: {
    img: '../../icons/advertisement.svg',
    alt: 'advertisement',
  },
  garbage: {
    img: '../../icons/garbage.svg',
    alt: 'garbage',
  },
  place: {
    img: '../../icons/place.svg',
    alt: 'place',
  },
  flag: {
    img: '../../icons/flag.png',
    alt: 'flag',
  },
  successful: {
    img: '../../icons/successful-sending.png',
    alt: 'successful',
  },
  rubiusLogo: {
    img: '../../icons/rubius_logo.png',
    alt: '',
  },
  imageBar: {
    toggleFullScreen: {
      img: '../../icons/imageBarIcons/toggleFullScreen.svg',
      alt: '',
    },
    zoomIn: {
      img: '../../icons/imageBarIcons/zoomIn.svg',
      alt: '',
    },
    zoomOut: {
      img: '../../icons/imageBarIcons/zoomOut.svg',
      alt: '',
    },
    resetZoom: {
      img: '../../icons/imageBarIcons/resetZoom.svg',
      alt: '',
    },
  },

};
