import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialog: {
    //animation: '$dialog 0.5s forwards ease-out',
    marginBottom: 30,
    '& h1': {
      marginTop: 12,
    },
    '& button': {
      marginBottom: 12,
    },
  },
  '@keyframes dialog': {
    '0%': {
      opacity: 0,
      marginTop: '-100px',
    },
    '100%': {
      opacity: 1,
      marginTop: '0px',
    },
  },
  dialogBlock: {
    marginBottom: 32,
    '& li': {
      padding: '18px 0 9px 0',
      borderBottom: '2px solid #EEEEEE',
    },
  },
  title: {
    fontSize: 24,
  },
  dialogTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: -0.41,
    color: theme.palette.common.black,
    textAlign: 'left',
  },
  dialogBlockContainer: {
    paddingLeft: 20,
    paddingTop: 15,
  },
  button: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    boxShadow: 'none',
  },
  closeButton: {
    padding: 0,
    minWidth: 24,
    height: 24,
    position: 'absolute',
    right: '1%',
    top: '2%',
    bottom: 25,
  },
}));

export default useStyles;
