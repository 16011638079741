import { AppBar, Avatar, Toolbar } from '@material-ui/core';

import ProfileHeader from './Components/ProfileHeader';
import NotProfileHeader from './Components/NotProfileHeader';

import { icons } from '../../Shared/Constants/icons';
import useStyles from './styles';
import { COLORS } from 'Shared/Constants/Colors';

interface IProps {
  isProfile?: boolean;
  settings?: boolean;
}

const Header = (props: IProps) => {
  const { isProfile = false, settings = true } = props;

  const c = useStyles({ color: COLORS.BaseRed });

  return (
    <div className={c.header}>
      <AppBar position="fixed">
        <Toolbar className={c.toolbar}>
          <div>{isProfile ? <ProfileHeader /> : <NotProfileHeader />}</div>

          {settings && (
            <div className={c.toolbarRight}>
              <Avatar src={icons.settings.img} alt={icons.settings.alt} className={c.smallSettings} />
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
