import { OffenseService } from 'ApiServices';
import { action, makeObservable, observable } from 'mobx';

import { ClaimTypes, RoutesNumber, TypeOfNotification } from 'Shared/Enums/enums';
import { IGetTypeOffenses } from 'Shared/Interfaces/IGetDto';

class AppStore {
  public isLoading = false;
  public routeNumber = RoutesNumber.DefaultRoute;
  public notification = { show: false, text: '', type: TypeOfNotification.Info };
  public reportTypeOffenses: IGetTypeOffenses[] | null = null;
  public offensesId: number | null = null;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      routeNumber: observable,
      notification: observable,
      reportTypeOffenses: observable,
      changeRouteNumber: action,
      getReportTypeOffenses: action,
      showNotification: action,
      getOffensesId: action,
      hideNotification: action,
      showLoader: action,
      hideLoader: action,
    });
  }

  public showLoader() {
    this.isLoading = true;
  }

  public hideLoader() {
    this.isLoading = false;
  }

  /**
   * изменение номера маршрута
   * @route - смотрим маршрут
   */
  public changeRouteNumber(type: ClaimTypes) {
    switch (type) {
      case ClaimTypes.ParkingClaim:
        this.routeNumber = RoutesNumber.CarRoute;
        break;
      case ClaimTypes.AdvertisingClaim:
        this.routeNumber = RoutesNumber.AdRoute;
        break;
      case ClaimTypes.RubbishClaim:
        this.routeNumber = RoutesNumber.TrashRoute;
        break;
      default:
        this.routeNumber = RoutesNumber.DefaultRoute;
    }

    // также изменим справочник категорий правонарушения
    this.getReportTypeOffenses(type);
  }

  /**
   * изменение номера маршрута
   * @route - смотрим маршрут
   */
  public changeReportTypeOffenses(reportTypeOffenses: IGetTypeOffenses[]) {
    this.reportTypeOffenses = reportTypeOffenses;
  }

  /**
   * получение id выбранного правонарушения для дальнейшей отправки на сервер
   * @offenses - массив названий выбранных правонарушений
   */
  public getOffensesId(offenses: string[]) {
    const offensesId = this.reportTypeOffenses?.find(item => offenses.includes(item.name));
    this.offensesId = offensesId?.id!;
  }

  /**
   * смена id выбранного правонарушения для дальнейшей отправки на сервер
   */
  public setOffensesId(id: number) {
    this.offensesId = id!;
  }

  /**
   * Показываем уведомление пользователю
   * @text - текст уведомления
   * @type - тип уведомления
   */
  public showNotification(text: string, type: TypeOfNotification) {
    this.notification = { show: true, text, type };
  }

  /** * Скрываем уведомление */
  public hideNotification() {
    this.notification = { show: false, text: '', type: TypeOfNotification.Info };
  }

  /**
   * Получение справочника правонарушений
   * @type - тип нарушения
   */
  public getReportTypeOffenses(type: ClaimTypes) {
    OffenseService.getReportTypeOffenses(type)
      .then(response => this.changeReportTypeOffenses(response))
      .catch(err => console.log(err));
  }
}

export default new AppStore();
