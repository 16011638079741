import { useStyles } from './styles';

const TermOfUse = () => {
  const c = useStyles();
  return (
    <div className={c.root}>
      <div className={c.titleBlock}>
        <p className={c.title}>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>1. Общие положения</p>
        <p>
          1.1. ООО «Рубиус Тех», ИНН 7024023627, КПП 701701001, ОГРН 1057004447807, адрес: Россия, Томская область, г.
          Томск, ул. Нахимова, д. 13/1, оф. 309, (далее – Rubius) предлагает пользователю (далее – Пользователь)
          использовать Приложение «Наш Томск», устанавливаемое на мобильное устройство Пользователя (далее –
          Приложение), на условиях, изложенных в настоящем Пользовательском соглашении (далее – Соглашение). Соглашение
          вступает в силу с момента выражения Пользователем согласия с его условиями в порядке, предусмотренном п. 1.4.
          Соглашения.
        </p>
        <p>
          1.2. Под приложением в рамках настоящего Соглашения понимается программа для операционных систем iOS и
          Android, обеспечивающая функционирование, графические решения (дизайн), контент (текстовую информацию), а
          также иные результатов интеллектуальной деятельности. Приложение позволяет обрабатывать фотоматериалы (далее –
          Контент) и отправлять обращения (текстовые с приложением фотоматериалов) в органы государственной власти и
          органы местного самоуправления.
        </p>
        <p>
          1.3. Соглашение может быть изменено Rubius без какого-либо специального уведомления Пользователя, новая
          редакция Соглашения вступает в силу с момента её размещения в личном кабинете Пользователя в Приложении, если
          иное не предусмотрено новой редакцией Соглашения.
        </p>
        <p>
          1.4. Начиная использовать Приложение, Пользователь считается принявшим условия Соглашения в полном объёме, без
          всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения,
          Пользователь не вправе использовать Приложение. В случае если Rubius были внесены какие-либо изменения в
          Соглашение в порядке, предусмотренном пунктом 1.3 Соглашения, с которыми Пользователь не согласен, он обязан
          прекратить использование Приложения.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>2. Регистрация Пользователя. Учётная запись Пользователя</p>
        <p>
          2.1. Для того, чтобы воспользоваться функциями Приложения, Пользователю необходимо пройти процедуру
          авторизации через Единую систему идентификации и аутентификации (ЕСИА), в результате которой для Пользователя
          будет создана уникальная учётная запись. Пользователь, достигший возраста 16 лет, вправе самостоятельно
          производить регистрацию учётной записи и использовать Приложение в пределах правоспособности, установленной
          применимым законодательством. В случае если Пользователь не достиг указанного возраста, а также в случаях,
          когда этого требует применимое законодательство, использование Приложения допускается только с согласия
          родителей или иных законных представителей.
        </p>
        <p>
          2.2. Если у Rubius есть основания полагать, что предоставленная Пользователем информация неполная или
          недостоверна, Rubius имеет право по своему усмотрению заблокировать либо удалить учётную запись Пользователя и
          отказать Пользователю в использовании Приложения.
        </p>
        <p>
          2.3. Персональная информация Пользователя, определенная Политикой конфиденциальности и содержащаяся в учётной
          записи Пользователя, хранится и обрабатывается Rubius в соответствии с условиями Политики конфиденциальности,
          расположенной в личном кабинете Пользования в Приложении.
        </p>
        <p>
          2.4. Пользователь самостоятельно несёт ответственность за выбранные им средства для доступа к учётной записи,
          а также самостоятельно обеспечивает их конфиденциальность. Пользователь самостоятельно несёт ответственность
          за все действия (а также их последствия) в рамках или с использованием Приложения под учётной записью
          Пользователя, включая случаи добровольной передачи Пользователем данных для доступа к учётной записи
          Пользователя третьим лицам на любых условиях (в том числе по договорам или соглашениям). При этом все действия
          в рамках или с использованием Приложения под учётной записью Пользователя считаются произведёнными самим
          Пользователем, за исключением случаев, когда Пользователь, в порядке, предусмотренном п. 2.9., уведомил Rubius
          о несанкционированном доступе к Приложению с использованием учётной записи Пользователя и/или о любом
          нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к учётной записи.
        </p>
        <p>
          2.5. Пользователь обязан немедленно уведомить Rubius о любом случае несанкционированного (не разрешённого
          Пользователем) доступа к Приложению с использованием учётной записи Пользователя и/или о любом нарушении
          (подозрениях о нарушении) конфиденциальности своих средств доступа к учётной записи. В целях безопасности
          Пользователь обязан самостоятельно осуществлять безопасное завершение работы под своей учётной записью (кнопка
          «Выход») по окончании каждой сессии работы с Приложением. Rubius не отвечает за возможную потерю или порчу
          данных, а также другие последствия любого характера, которые могут произойти из-за нарушения Пользователем
          положений этой части Соглашения.
        </p>
        <p>
          2.6. Бездействие со стороны Rubius в случае нарушения Пользователем положений ПС не лишает Rubius права
          предпринять соответствующие действия в защиту своих интересов в более поздние сроки, а также не означает
          отказа от своих прав в случае совершения в последующем подобных либо сходных нарушений.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>3. Контент Пользователя</p>
        <p>
          3.1. Пользователь самостоятельно несёт ответственность за соответствие содержания размещаемого Пользователем
          Контента требованиям действующего законодательства, включая ответственность перед третьими лицами в случаях,
          когда размещение Пользователем того или иного Контента или содержание Контента нарушает права и законные
          интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих
          лиц, и/или посягает на принадлежащие им нематериальные блага.
        </p>
        <p>
          3.2. Пользователь признает и соглашается с тем, что Rubius не обязан просматривать Контент любого вида,
          размещаемый Пользователем в Приложении, а также то, что Rubius имеет право (но не обязанность) по своему
          усмотрению отказать Пользователю в размещении им Контента или удалить любой Контент, который доступен
          посредством Приложения. Пользователь осознает и согласен с тем, что он должен самостоятельно оценивать все
          риски, связанные с использованием Контента, включая оценку надёжности, полноты или полезности этого Контента.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>4. Условия и порядок использования Приложения</p>
        <p>
          4.1. Пользователь самостоятельно несёт ответственность перед третьими лицами за свои действия, связанные с
          использованием Приложения, в том числе, если такие действия приведут к нарушению прав и законных интересов
          третьих лиц, а также за соблюдение законодательства при использовании Приложения.
        </p>
        <p>
          4.2. Пользователь не вправе воспроизводить, повторять и копировать, продавать и перепродавать, а также
          использовать для каких-либо коммерческих целей какие-либо части Приложения или доступ к нему, кроме тех
          случаев, когда Пользователь получил такое разрешение от Rubius, либо когда это прямо предусмотрено ПС.
        </p>
        <p>
          4.3. Использование контента, а также каких-либо иных элементов Приложения возможно только в рамках
          функционала, предлагаемого Приложением. Никакие элементы содержания Приложения не могут быть использованы иным
          образом без предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе:
          воспроизведение, копирование, переработка, распространение на любой основе, отображение во фрейме и т.д.
          Исключение составляют случаи, прямо предусмотренные законодательством РФ или ПС.
        </p>
        <p>
          4.4. Контент, размещённый в Приложении Пользователем, и результаты, полученные после обработки Контента,
          являются объектом исключительных прав такого Пользователя.
        </p>
        <p>4.5. Использование Приложения осуществляется на безвозмездной основе.</p>
        <p>4.6. Пользователем Приложения может выступать любое физическое лицо.</p>
        <p>4.8. Технические условия:</p>
        <ul>
          <li>– наличие мобильного телефона под управлением iOS или Android; </li>
          <li>– рекомендуемое разрешение фотографий: не менее 3264 на 2448 пикселей;</li>
          <li>– рекомендуемый Интернет-канал: 3G и лучше</li>
        </ul>
        <p>
          Все вопросы, связанные с приобретением прав доступа в Интернет с требуемыми значениями Интернет-каналов,
          наладкой необходимого программного обеспечения Пользователь решает самостоятельно.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>5. Отсутствие гарантий, ограничение ответственности</p>
        <p>
          5.1. Пользователь использует Приложение на свой собственный риск. Приложение предоставляется «как есть».
          Rubius не принимает на себя никакой ответственности, в том числе за соответствие Приложения целям
          Пользователя.
        </p>
        <p>
          5.2. Rubius не гарантирует, что: Приложение соответствует/будет соответствовать требованиям Пользователя;
          Приложение будет предоставляться непрерывно, быстро, надёжно и без ошибок; результаты, которые могут быть
          получены с использованием Приложения, будут точными и надёжными и могут использоваться для каких-либо целей
          или в каком-либо качестве (например, для установления и/или подтверждения каких-либо фактов).
        </p>
        <p>
          5.3. Rubius не несёт ответственности за любые виды убытков, произошедшие вследствие использования
          Пользователем Приложения или отдельных частей/функций Приложения.
        </p>
        <p>
          5.4. При любых обстоятельствах ответственность Rubius в соответствии со статьёй 15 Гражданского кодекса России
          ограничена 10 000,00 (десятью тысячами) рублей РФ и возлагается на него при наличии в его действиях вины.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>6. Заключительные положения</p>
        <p>
          6.1. Настоящее Соглашение представляет собой договор между Пользователем и Rubius относительно порядка
          использования Приложения и заменяет собой все предыдущие соглашения между Пользователем и Rubius.
        </p>
        <p>
          6.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации.
          Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством
          Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением,
          разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского
          права. Везде по тексту настоящего Соглашения, если явно не указано иное, под термином «законодательство»
          понимается как законодательство Российской Федерации.
        </p>
        <p>
          6.3. Ничто в Соглашении не может пониматься как установление между Пользователем и Rubius агентских отношений,
          отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных
          отношений, прямо не предусмотренных Соглашением.
        </p>
        <p>
          6.4. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны
          недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или
          применимость остальных положений Соглашения.
        </p>
      </div>

      <div>
        <p className={`${c.text} ${c.update}`}>Дата обновления: 06.08.2021</p>
      </div>
    </div>
  );
};

export default TermOfUse;
