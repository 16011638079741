import * as yup from 'yup';

export const validationFeedback = yup.object({
  email: yup
    .string()
    .email('Введите корректный email')
    .required('Обязательное поле'),
  description: yup
    .string()
    .min(5, 'Минимальное количество символов 5')
    .required('Обязательное поле'),
});
