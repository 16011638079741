import { useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { dashboardStore } from 'Stores';

import { Container, TextField } from '@material-ui/core';
import { Filter, Statistic } from './Components';
import { CustomTable } from 'Components';
import {
  AccessTime as AccessTimeIcon,
  Close as RejectIcon,
  Done as DoneIcon,
  EmojiEvents as EmojiEventsIcon,
  MenuBook as MenuBookIcon,
} from '@material-ui/icons';

import { COLORS } from 'Shared/Constants/Colors';
import { ClaimStatuses, ClaimTypes } from 'Shared/Enums/enums';
import { IDashboardHistoryDto, ITableColumn } from 'Shared/Interfaces/Interfaces';

import { Routes } from 'Shared/Constants/Constants';
import Utils from 'Shared/Utils/Utils';

import useStyles from './styles';

interface IIcon {
  img: string;
  alt: string;
}

const DashboardPage = () => {
  const c = useStyles();
  const history = useHistory();

  useEffect(() => {
    dashboardStore.postTotalStatistics();
  }, []);

  const createImage = (icon: IIcon, styles: any) => <img className={styles} src={icon.img} alt={icon.alt} />;

  const addPictureForStatuses = (claimStatus: ClaimStatuses) => {
    switch (claimStatus) {
      case ClaimStatuses.Pending:
        return (
          <AccessTimeIcon style={{ color: COLORS.BaseBlue, width: '16px', height: '16px' }} className={c.imgStatus} />
        ); // временная иконка
      case ClaimStatuses.Declined:
        return <RejectIcon className={c.imgStatus} />;
      case ClaimStatuses.Accepted:
        return <DoneIcon className={c.imgStatus} color="secondary" />;
      case ClaimStatuses.Finished:
        return <EmojiEventsIcon className={c.imgStatus} color="secondary" />;
      case ClaimStatuses.Registered:
        return <MenuBookIcon className={c.imgStatus} color="secondary" />;
    }
  };

  const columns: ITableColumn[] = [
    {
      key: 'date',
      label: 'Время подачи',
      sortable: true,
      minWidth: '100px',
      align: 'left',
      cell: (row: IDashboardHistoryDto) => {
        return <div>{moment(row.date).format('DD.MM.YYYY HH:mm')}</div>;
      },
    },
    {
      key: 'reportTypeId',
      label: 'Тип уведомления',
      sortable: true,
      minWidth: '120px',
      align: 'left',
      cell: (row: IDashboardHistoryDto) => {
        const typeString =
          row.reportTypeId === ClaimTypes.ParkingClaim
            ? 'Парковка в неположенном месте'
            : row.reportTypeId === ClaimTypes.AdvertisingClaim
            ? 'Реклама в неположенном месте'
            : 'Мусор в неположенном месте';
        return <div>{typeString}</div>;
      },
    },

    {
      key: 'pnones',
      label: 'Телефоны',
      sortable: true,
      minWidth: '125px',
      maxWidth: '150px',
      align: 'left',
      cell: (row: IDashboardHistoryDto) => {
        return <div style={{ wordBreak: 'break-word' }}>{row.phones}</div>;
      },
      filter: () => {
        const onChangePattern = (event: React.ChangeEvent<HTMLInputElement>) => {
          dashboardStore.filter.setPhone(event.target.value);
        };

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>Введите телефон</div>
            <TextField color={'secondary'} onChange={onChangePattern} value={dashboardStore.filter.phone} />
          </div>
        );
      },
      hasFilterValue: () => !!dashboardStore.filter.phone,
      onClearFilter: () => dashboardStore.filter.setPhone(''),
    },
    {
      key: 'address',
      label: 'Адрес',
      sortable: true,
      minWidth: '150px',
      align: 'left',
      cell: (row: IDashboardHistoryDto) => {
        return <div>{row.address}</div>;
      },
      filter: () => {
        const onChangePattern = (event: React.ChangeEvent<HTMLInputElement>) => {
          dashboardStore.filter.setAddress(event.target.value);
        };

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>Введите адрес</div>
            <TextField color={'secondary'} onChange={onChangePattern} value={dashboardStore.filter.address} />
          </div>
        );
      },
      hasFilterValue: () => !!dashboardStore.filter.address,
      onClearFilter: () => dashboardStore.filter.setAddress(''),
    },
    {
      key: 'userId',
      label: 'Id пользователя',
      sortable: true,
      minWidth: '90px',
      align: 'center',
      cell: (row: IDashboardHistoryDto) => {
        return <div>{row.userId}</div>;
      },
      filter: () => {
        const onChangePattern = (event: React.ChangeEvent<HTMLInputElement>) => {
          dashboardStore.filter.setUserId(event.target.value);
        };

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>Введите Id пользователя</div>
            <TextField color={'secondary'} onChange={onChangePattern} value={dashboardStore.filter.userId} />
          </div>
        );
      },
      hasFilterValue: () => !!dashboardStore.filter.userId,
      onClearFilter: () => dashboardStore.filter.setUserId(''),
    },

    {
      key: 'stateId',
      label: 'Статус',
      sortable: true,
      minWidth: '140px',
      align: 'center',
      cell: (row: IDashboardHistoryDto) => {
        const stateString = Utils.getStateName(row.stateId);

        return (
          <>
            <div className={c.columnStatus}>
              <div className={c.columnStatusIcon}>{addPictureForStatuses(row.stateId)}</div>
              <div className={c.columnStatusLabel}>{stateString}</div>
            </div>
          </>
        );
      },
    },
    {
      key: 'comment',
      label: 'Причина отклонения',
      sortable: true,
      minWidth: '100px',
      align: 'left',
      cell: (row: IDashboardHistoryDto) => {
        return <div>{row.comment}</div>;
      },
    },
  ];

  const handleClick = (item: IDashboardHistoryDto) => {
    history.push(Routes.COMMON.CLAIM_DETAIL + `/?id=${item.id}&type=${item.reportTypeId}`);
  };

  return (
    <div className={c.dashboardPage}>
      <Container>
        <Filter />
        <Statistic className={c.statisctic} statistics={dashboardStore.totalStatistics} />

        <p className={c.title}>Подробная информация</p>
        <div className={c.tableWrapper}>
          {dashboardStore.statistics.length !== 0 && (
            <CustomTable
              styleOptions={{ height: '100%', tableCellPadding: '10px' }}
              data={dashboardStore.filteredStatistic}
              columns={columns}
              onClick={handleClick}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default observer(DashboardPage);
