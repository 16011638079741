import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container } from '@material-ui/core';

import { ButtonApp, Header } from 'Components';

import { Routes } from 'Shared/Constants/Constants';

import { icons } from 'Shared/Constants/icons';

import useStyles from './styles';
import { appStore } from 'Stores';
import { RoutesNumber } from 'Shared/Enums/enums';

const SuccessfulSubmissionPage = () => {
  const c = useStyles();
  const routeNumber = appStore.routeNumber;

  const history = useHistory();

  const onClick = () => history.push(Routes.MAIN);

  const getTitle = () => {
    switch (routeNumber) {
      case RoutesNumber.CarRoute:
        return 'Информация отправлена!';
      case RoutesNumber.AdRoute:
        return 'Сообщение отправлено в Санитарную милицию';
      case RoutesNumber.TrashRoute:
        return 'Сообщение отправлено в САХ';
    }

    return 'Информация отправлена!';
  };

  return (
    <div className={c.root}>
      <Header isProfile={false} settings={false} />

      <Container className={c.container}>
        <div className={c.img}>
          <img src={icons.successful.img} alt={icons.successful.alt} />
        </div>

        <h1>{getTitle()}</h1>

        <div>
          <p className={c.text}>Результат проверки появится в личном кабинете.</p>
          <p className={c.text}>Спасибо, что помогаете поддерживать город в чистоте!</p>
        </div>

        <ButtonApp onClick={onClick}>Понятно</ButtonApp>
      </Container>
    </div>
  );
};

export default observer(SuccessfulSubmissionPage);
