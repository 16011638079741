import { observer } from 'mobx-react';
import { Alert } from '@material-ui/lab';

import { appStore } from 'Stores';

import useStyles from './styles';

const Notification = () => {
  const c = useStyles();

  return (
    <>
      {appStore.notification.show && (
        <Alert
          severity={appStore.notification.type}
          className={c.notice}
          onClose={() => {
            appStore.hideNotification();
          }}>
          <pre className={c.noticeContent}>{appStore.notification.text}</pre>
        </Alert>
      )}
    </>
  );
};

export default observer(Notification);
