export const AdTips = [
  'Сфотографируйте издалека, чтобы по фото можно было узнать место',
  'Сфотографируйте объявление вблизи, чтобы было видно номер телефона',
];

export const CarTips = [
  'Сфотографируйте издалека, чтобы по фото можно было узнать место',
  'Сфотографируйте ближе, чтобы было видно номер автомобиля',
];

export const TrashTips = [
  'Сфотографируйте или запишите видео издалека, чтобы по фото можно было узнать место',
  'Сфотографируйте или запишите видеоближе, чтобы понять размеры незаконной свалки',
];
