import { useState, MouseEvent, ReactNode } from 'react';
import { Filter as FilterIcon } from 'react-feather';

import { IconButton, Popover } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Delete';

import useStyles from './styles';
import clsx from 'clsx';
import { KeyCodes } from 'Shared/Constants/KeyCodes';

interface IProps {
  className?: string;
  hasValue?: boolean;
  children?: ReactNode;
  onClear?: () => void;
}

const Filter = (props: IProps) => {
  const { className, hasValue, children, onClear } = props;

  const c = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleClear = () => {
    if (onClear) {
      onClear();
    }

    handleClose();
  };

  const handleKeyUp = (e: any) => {
    if (e.keyCode === KeyCodes.ENTER) handleClose();
  };

  return (
    <>
      <IconButton id="filter" className={className} size="small" onClick={handleClick}>
        <FilterIcon id="filterIcon" className={hasValue ? clsx(c.active, 'active') : ''} width={16} />
      </IconButton>

      <Popover
        className={c.filterPopover}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        onKeyUp={handleKeyUp}>
        <div className={c.card}>
          <div className={c.content}>
            {children}
            <div className={c.actions}>
              <IconButton size="small" onClick={handleClear}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default Filter;
