import { observer } from 'mobx-react';

import VideocamIcon from '@material-ui/icons/Videocam';
import { IPhotoView } from 'Shared/Interfaces/Interfaces';

import useStyles from './styles';
import { fullImageStore } from 'Stores';

interface IProps {
  photos: IPhotoView[];
}

const Photos = ({ photos }: IProps) => {
  const c = useStyles();

  return (
    <div className={c.photos}>
      {photos &&
        photos.length > 0 &&
        photos.map((item, index) => {
          const handleOpenImage = () => {
            fullImageStore.setSelectedImage(photos, index);
          };

          return (
            <div key={index} style={{ position: 'relative' }} onClick={handleOpenImage}>
              <img className={c.photoPreview} src={item.photo} alt="" style={{ display: 'block' }} />
              {item.isVideo && <VideocamIcon className={c.videoIcon} color="primary" />}
            </div>
          );
        })}

      {(!photos || photos.length === 0) && <p className={c.text}> Ошибка загрузки фотографий... </p>}
    </div>
  );
};

export default observer(Photos);
