import { createMuiTheme } from '@material-ui/core';
import { COLORS } from 'Shared/Constants/Colors';

export const theme = createMuiTheme({
  palette: {
    common: {
      white: COLORS.White,
      black: '#000000',
    },
    primary: {
      main: COLORS.BaseRed,
      contrastText: '#414042',
      100: COLORS.BaseGreen,
    },

    secondary: {
      main: '#288349',
    },

    grey: {
      [600]: '#292C33',
    },
  },
  typography: {
    fontFamily: 'SF Pro Text',
  },
});

export const materialTheme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    primary: {
      main: COLORS.BaseGreen,
    },
  },
});
