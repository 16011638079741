import { useCallback, useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { observer } from 'mobx-react';

import { claimsStore } from 'Stores';

import { WrapperPage } from 'Components';

import { DEFAULT_MAP_CENTER, Routes } from 'Shared/Constants/Constants';

import useStyles from './styles';

const DisplayPosition = (props: any) => {
  const { map } = props;

  const onMove = useCallback(() => claimsStore.getPosition(map.getCenter()), [map]);

  useEffect(() => {
    map.on('move', onMove);
    return () => map.off('move', onMove);
  }, [map, onMove]);

  return null;
};

const LocationPage = () => {
  const position = claimsStore.imageCoordinates ?? claimsStore.coordinates;

  const c = useStyles();

  const [map, setMap] = useState<any>(null);

  const zoom = position === DEFAULT_MAP_CENTER ? 12 : 16;

  return (
    <WrapperPage className={c.mapContainer} title="Местоположение" to={Routes.DIALOG}>
      <>
        <div className={c.mapAlert}>
          <p>Отметьте место на карте</p>
        </div>

        {map ? <DisplayPosition map={map} /> : null}

        <MapContainer center={position} zoom={zoom} scrollWheelZoom={false} whenCreated={setMap}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>Вы находитесь здесь</Popup>
          </Marker>
        </MapContainer>
      </>
    </WrapperPage>
  );
};

export default observer(LocationPage);
