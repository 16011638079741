import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './styles';

interface IProps {
  size?: number;
  className?: string;
}

const Spinner = (props: IProps) => {
  const { size = 20, className } = props;

  const c = useStyles();

  return (
    <div className={clsx(c.spinner, className)}>
      <CircularProgress size={size} className={c.progress} />
    </div>
  );
};

export default Spinner;
