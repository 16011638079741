import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  notice: {
    position: 'fixed',
    top: 44,
    width: '100%',
    zIndex: 100,
  },
  noticeContent: {
    textAlign: 'left',
  },
}));

export default useStyles;
