import HTTPService from './HttpService';

import { BASE_PATH } from 'Shared/Constants/Constants';
import { IAddVideoDto } from 'Shared/Interfaces/Interfaces';

class VideoService extends HTTPService {
  constructor() {
    super(BASE_PATH.VIDEO);
  }

  public addVideo(body: IAddVideoDto) {
    const data = new FormData();
    data.append('file', body.file);

    return this.POST_FILE(`/${body.id}/${body.isLast}`, data);
  }
}

export default new VideoService();
