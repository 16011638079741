import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  imgType: {
    width: 25,
    height: 25,
    filter: 'invert(1)',
  },
  imgStatus: {
    width: 15,
    height: 15,
  },
}));

export default useStyles;
