import { observer } from 'mobx-react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { appStore } from 'Stores';

import { DialogPage, LocationPage, PhotoPage } from 'Modules';

import { Routes } from 'Shared/Constants/Constants';
import { RoutesNumber } from 'Shared/Enums/enums';

const ClaimRoutes = () => {
  const routeNumber = appStore.routeNumber;

  const location = useLocation();

  const claimRoutes = [Routes.PHOTO, Routes.LOCATION, Routes.DIALOG];

  if (!claimRoutes.includes(location.pathname)) return null;

  return routeNumber !== RoutesNumber.DefaultRoute ? (
    <>
      <Route path={Routes.PHOTO} component={PhotoPage} exact={true} />
      <Route path={Routes.LOCATION} component={LocationPage} exact={true} />
      <Route path={Routes.DIALOG} component={DialogPage} exact={true} />
    </>
  ) : (
    <Redirect to={Routes.MAIN} />
  );
};

export default observer(ClaimRoutes);
