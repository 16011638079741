import { action, makeObservable, observable } from 'mobx';
import { IPhotoView } from 'Shared/Interfaces/Interfaces';

class FullImageStore {
  /** Full screen image index */
  public selectedImageIndex: number | null = null;

  constructor() {
    makeObservable(this, {
      selectedImageIndex: observable,

      setSelectedImage: action,
      changeSelectedImage: action,
      clearSelectedImage: action,
    });
  }

  public setSelectedImage(photos: IPhotoView[], index: number) {
    if (photos.length < index + 1) index = photos.length - 1;
    else if (index + 1 <= 0) index = 0;
    this.selectedImageIndex = index + 1;
  }

  public changeSelectedImage(photos: IPhotoView[], isNext: boolean) {
    let newIndex = (this.selectedImageIndex ?? 0) + 1 * (isNext ? 1 : -1);

    if (photos.length < newIndex) newIndex = photos.length;
    else if (newIndex === 0) newIndex = 1;
    this.selectedImageIndex = newIndex;
  }

  public clearSelectedImage() {
    this.selectedImageIndex = null;
  }
}

export default new FullImageStore();
