import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import useStyles from '../styles';
import clsx from 'clsx';
import { COLORS } from 'Shared/Constants/Colors';

interface IProps {
  className?: string;
  styleOptions?: IHeaderStyleOptions;
  onBack?: () => void;
}

export interface IHeaderStyleOptions {
  color: string;
}

const ProfileHeader = ({ className, styleOptions, onBack }: IProps) => {
  const c = useStyles(styleOptions || { color: COLORS.BaseGreen });
  const history = useHistory();

  const onClick = () => {
    if (onBack) onBack();
    else history.goBack();
  };

  return (
    <Button className={clsx(c.button, className)} startIcon={<div className={c.buttonArrow}></div>} onClick={onClick}>
      Назад
    </Button>
  );
};

export default ProfileHeader;
