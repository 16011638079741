import { useHistory } from 'react-router-dom';
import { Routes } from 'Shared/Constants/Constants';
import { ClaimStatuses, ClaimTypes } from 'Shared/Enums/enums';
import useStyles from './styles';

interface IProps {
  title: string;
  pageElements: Array<{
    id: number;
    description: string;
    reportType: ClaimTypes;
    claimTypeIcon: JSX.Element;
    status: ClaimStatuses;
    claimStatusIcon: JSX.Element;
  }>;
}

const ArchivePage = (props: IProps) => {
  const { title, pageElements } = props;

  const c = useStyles();
  const history = useHistory();

  const onClick = (id: number, type: ClaimTypes) => {
    history.push(Routes.COMMON.CLAIM_DETAIL + `/?id=${id}&type=${type}`);
  };

  return (
    <div className={c.root}>
      <p className={c.title}>{title}</p>
      {pageElements.map((elem, index) => {
        const handleClick = () => onClick(elem.id, elem.reportType);
        return (
          <ul className={c.list} key={index} onClick={handleClick}>
            <li>{elem.claimTypeIcon}</li>
            <li>
              <pre className={c.claimName}>{elem.description}</pre>
            </li>
            <li>{elem.claimStatusIcon}</li>
          </ul>
        );
      })}
    </div>
  );
};

export default ArchivePage;
