import { makeStyles, Theme } from '@material-ui/core';

import { IStyles } from './ProfilePage';

const useStyles = makeStyles<Theme, IStyles>(theme => ({
  profilePage: {
    // animation: '$profilePage 0.5s forwards ease-out',
    marginBottom: 30,
  },
  '@keyframes profilePage': {
    '0%': {
      opacity: 0,
      marginTop: '-100px',
    },
    '100%': {
      opacity: 1,
      marginTop: '0px',
    },
  },
  profileContainer: {
    position: 'relative',
  },
  logOut: {
    position: 'absolute',
    top: 18, // TODO когда будет использоваться UserRating вернуть на 10
    right: 16,
    //color: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    fontSize: 17,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: -0.408,
  },
  statistic: {
    margin: '20px 0',
    borderBottom: '2px solid #EEEEEE',
    paddingBottom: 20,
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
    '& span': {
      width: 10,
      height: 10,
      border: '2px solid black',
      borderLeft: 'transparent',
      borderTop: 'transparent',
      display: 'inline-block',
      transform: propsStyles => propsStyles.transform,
      transition: 'all 500ms',
      marginLeft: 10,
    },
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: '-0.41px',
    color: theme.palette.common.black,
    textAlign: 'left',
  },
  body: {
    overflow: 'auto',
    transition: 'all 500ms',
    height: propsStyles => propsStyles.height,
    opacity: propsStyles => propsStyles.opacity,
  },
  review: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '15px',
    lineHeight: '19px',
    letterSpacing: '-0.24px',
    color: theme.palette.secondary.main,
    textAlign: 'left',
  },
}));

export default useStyles;
