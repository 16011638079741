import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';

import { Header } from 'Components';

import { Agreement, Policy, TabPanel, Tabs, TermOfUse } from './Components';

import { useStyles } from './styles';

const tabs = [
  { label: 'Обработка данных', text: <Agreement /> },
  { label: 'Политика конфиденц.', text: <Policy /> },
  { label: 'Польз-кое соглашение', text: <TermOfUse /> },
];

const AgreementsPage = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const c = useStyles();

  const handleChange = (e: any, newValue: number) => {
    setValue(newValue);
    window.scrollTo(0, 0);
  };

  const handleChangeIndex = (index: number) => setValue(index);

  return (
    <div className={c.agreementsPage}>
      <Header isProfile={true} settings={false} />

      <div className={c.root}>
        <Tabs tabs={tabs} value={value} handleChange={handleChange} />

        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}>
          {tabs.map((tab, index) => (
            <TabPanel value={value} index={index} key={index} dir={theme.direction}>
              {tab.text}
            </TabPanel>
          ))}
        </SwipeableViews>
      </div>
    </div>
  );
};

export default AgreementsPage;
