import { makeStyles } from '@material-ui/core';
import { COLORS } from 'Shared/Constants/Colors';

const useStyles = makeStyles(theme => ({
  phone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  phoneInput: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: 0.5,
    color: theme.palette.common.black,
    width: 284,
    height: 56,
    background: theme.palette.common.white,
    border: `1px solid ${COLORS.BorderGray}`,
    borderRadius: 4,
    padding: '8px 20px',
    marginBottom: theme.spacing(2),
  },
  errorInput: {
    border: `2px solid ${COLORS.BaseRed}`,
  },
  phoneButtonsContainer: {
    marginTop: 9,
    alignSelf: 'start',
    display: 'flex',
  },
  addPhoneButton: {
    marginLeft: theme.spacing(0.75),
  },
  deletePhoneButtonContainer: {
    marginTop: 9,
    alignSelf: 'start',
  },
  deletePhoneButton: {
    marginLeft: theme.spacing(0.75),
  },
}));

export default useStyles;
