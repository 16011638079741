import { action, makeObservable, observable } from 'mobx';

import { appStore } from 'Stores';
import { ClaimService } from 'ApiServices';

import { ITotalStatistics } from 'Shared/Interfaces/ITotalStatisticDto';
import { FilterModel } from 'Shared/Models';
import { IFilterData, IDashboardHistoryDto } from 'Shared/Interfaces/Interfaces';
import Utils from 'Shared/Utils/Utils';
import { ClaimStatuses } from 'Shared/Enums/enums';
class DashboardStore {
  public filter: FilterModel = new FilterModel(() => this.applyFilter());
  public totalStatistics: ITotalStatistics | null = null;
  public statistics: IDashboardHistoryDto[] = [];
  public filteredStatistic: IDashboardHistoryDto[] = [];

  constructor() {
    makeObservable(this, {
      totalStatistics: observable,
      statistics: observable,
      filteredStatistic: observable,

      postTotalStatistics: action,
      applyFilter: action,
    });
  }

  /** * получение статистики общих данных с сервера по фильтру */
  public async postTotalStatistics() {
    appStore.showLoader();
    try {
      const statistics: IDashboardHistoryDto[] = [];

      const resultFilter: IFilterData = {
        stateId: this.filter.stateId,
        startDate: Utils.toUtcDateString(this.filter.startDate),
        finishDate: Utils.toUtcDateString(this.filter.finishDate),
      };

      const response = await ClaimService.postTotalStatistics(resultFilter);
      if (!response) return;

      response.reportList.forEach((item, index) => {
        const obj: IDashboardHistoryDto = {
          id: item.id,
          date: item.creationDate,
          stateId: item.stateId,
          reportTypeId: item.reportTypeId,
          comment: item.comment,

          phones: item.phones ? item.phones?.replaceAll(';', ' ') : '',
          address: item.address,
          userId: item.userId,
        };
        statistics.push(obj);
      });

      this.statistics = statistics;
      this.totalStatistics = {
        acceptedCount: response.acceptedCount,
        declinedCount: response.declinedCount,
        pendingCount: response.pendingCount,
        totalCount: response.totalCount,
        registeredCount: response.reportList.filter(r => r.stateId === ClaimStatuses.Registered).length,
        finishedCount: response.reportList.filter(r => r.stateId === ClaimStatuses.Finished).length,
      };

      this.applyFilter();
    } catch (err) {
      console.log(err);
    } finally {
      appStore.hideLoader();
    }
  }

  public applyFilter() {
    this.filteredStatistic = this.statistics;

    if (this.filter.phone) {
      this.filteredStatistic = this.statistics.filter(s => s.phones?.includes(this.filter.phone));
    }

    if (this.filter.address) {
      this.filteredStatistic = this.filteredStatistic.filter(s => s.address?.includes(this.filter.address));
    }

    if (this.filter.userId) {
      const parsedUserId = parseInt(this.filter.userId);
      if (!!parsedUserId) this.filteredStatistic = this.filteredStatistic.filter(s => s.userId === parsedUserId);
    }
  }
}

export default new DashboardStore();
