import { useRef, useState } from 'react';
import useStyles from './styles';

interface IProps {
  downloadLink: string | null;
  isVideoFull: boolean;
  className: string;
}

export const VideoPlayer = (props: IProps) => {
  const { downloadLink, isVideoFull, className } = props;
  const c = useStyles();

  const videoPlayerRef = useRef<HTMLVideoElement>(null);

  const [isVideoPlay, setIsVideoPlay] = useState<boolean>(false);

  const onPlayerStart = () => {
    if (!videoPlayerRef.current) return;

    if (videoPlayerRef.current.paused) {
      videoPlayerRef.current.play();
      setIsVideoPlay(true);
    } else {
      videoPlayerRef.current.pause();
    }
  };

  const onPlayerPause = () => {
    setIsVideoPlay(false);
  };

  const setPlayerStop = () => {
    if (!videoPlayerRef.current || videoPlayerRef.current.paused) return;
    videoPlayerRef.current.pause();
  };

  return (
    <div>
      {isVideoFull && downloadLink && !isVideoPlay && <button className={c.playPause} onClick={onPlayerStart} />}

      <video
        className={className}
        ref={videoPlayerRef}
        controls={false}
        onPause={onPlayerPause}
        onClick={setPlayerStop}
        preload="auto"
        src={downloadLink ? downloadLink : ''}
        style={isVideoFull && downloadLink ? {} : { visibility: 'hidden' }}
      />
    </div>
  );
};
