import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
  },
  title: {
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: -0.41,
    color: theme.palette.common.black,
    textAlign: 'left',
    width: '100%',
    padding: 5,
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px',
    borderBottom: '1.5px solid rgba(0, 0, 0, 0.1)',
    marginBottom: 5,
    cursor: 'pointer',
    '& li:not(:last-child)': {
      marginRight: 15,
    },
  },
  claimName: {
    fontFamily: theme.typography.fontFamily,
    lineHeight: 1.2,
    whiteSpace: 'pre-wrap',
  },
}));

export default useStyles;
