import { makeStyles } from '@material-ui/core';
import { COLORS } from 'Shared/Constants/Colors';

const useStyles = makeStyles(theme => ({
  grayButton:{
    backgroundColor: `${COLORS.GrayBlock} !important`,

    '&.Mui-disabled': {
      color: `${COLORS.White}`,
  },
},

  containerText: {
    textAlign: 'left',
    marginBottom: 18,
    paddingRight: 50,
    '& p': {
      fontFamily: 'SF Pro Text',     
      color: COLORS.BorderGray,
      fontSize: 17,
      marginTop: 32,
    },
  },
  button: {
    width: '100%',
    height: 144,
    marginBottom: 20,
    background: COLORS.BaseGreen,
    boxShadow: '0px 3px 0px #929292',
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: 22,
    // lineHeight: 20,
    letterSpacing: '-0.5px',
    fontFamily: theme.typography.fontFamily,
    textTransform: 'none',
    borderRadius: 24,
    border: 'none',
    padding: '6px 8px',
    '&:hover': {
      background: COLORS.BaseGreen,
    },
    '@media (max-width: 480px)': {
      height: 130,
      fontSize: 18,
    },
    '@media (max-width: 383px)': {
      height: 120,
      fontSize: 17,
    },
    '@media (max-width: 368px)': {
      height: 110,
      fontSize: 16,
    },
    '@media (max-width: 339px)': {
      height: 100,
      fontSize: 15,
    },
    '@media (max-width: 322px)': {
      height: 90,
      fontSize: 14,
    },
    '@media (max-width: 296px)': {
      height: 80,
      fontSize: 13,
    },
  },
  buttonChildren: {
    // width: 320,
    // display: 'flex',
    display: 'contents',
    alignItems: 'center',
    // '@media (max-width: 480px)': {
    // 	width: 260,
    // },
  },
  icon: {
    width: 48,
    height: 48,
    marginRight: 18,
    '@media (max-width: 480px)': {
      width: 38,
      height: 38,
      marginRight: 16,
    },
    '@media (max-width: 360px)': {
      width: 30,
      height: 30,
      marginRight: 12,
    },
    '@media (max-width: 320px)': {
      width: 25,
      height: 25,
      marginRight: 8,
    },
  },
}));

export default useStyles;
