import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  splashScreen: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '500px',
  },
  control: {
    // animation: '$control 1s forwards ease-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 40,
    '& p': {
      fontSize: '15px',
      lineHeight: '19px',
      textAlign: 'center',
      color: '#414042',
      marginBottom: 10,
      fontFamily: theme.typography.fontFamily,
    },
  },
  '@keyframes control': {
    '0%': {
      opacity: '0',
      transform: 'translateY(-100%)',
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0%)',
    },
  },
}));
