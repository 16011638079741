import { TableContainer, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import EnhancedTableHeader from 'Components/Table/EnhancedTableHeader/EnhancedTableHeader';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { ITableColumn } from 'Shared/Interfaces/Interfaces';
import Utils from 'Shared/Utils/Utils';
import useStyles from './styles';

interface IProps {
  data: any[];
  columns: ITableColumn[];
  styleOptions?: IHeaderStyleOptions;
  onClick?: (item: any) => void;
  onDoubleClick?: (item: any) => void;
}
export interface IHeaderStyleOptions {
  height: string;
  tableCellPadding: string;
}

const CustomTable = (props: IProps) => {
  const { data, styleOptions, columns, onClick, onDoubleClick } = props;
  const c = useStyles(styleOptions || { height: '100%', tableCellPadding: '0px' });

  const [filterData, setFilterData] = useState<any[]>(data);
  const [order, setOrder] = useState<'asc' | 'desc'>();
  const [orderBy, setOrderBy] = useState<string>();

  useEffect(() => {
    const filteredData = Utils.sort<typeof data>(order === 'asc' ? true : false, data, orderBy as keyof typeof data);
    setFilterData(filteredData);
  }, [data, data.length, order, orderBy]);

  const renderRow = (row: any, cols: ITableColumn[]) => {
    return cols.map(column => {
      return (
        <TableCell
          variant="body"
          className={c.tableCell}
          key={column.key}
          align={column.align}
          style={{ maxWidth: column.maxWidth, minWidth: column.minWidth, width: column.width }}>
          {column.cell ? column.cell(row) : row[column.key]}
        </TableCell>
      );
    });
  };

  const renderRows = () => {
    return filterData.map((item, index) => {
      const handleClick = () => {
        if (onClick) onClick(item);
      };

      const handleDoubleClick = () => {
        if (onDoubleClick) onDoubleClick(item);
      };

      return (
        <Fragment key={index}>
          <TableRow
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            hover={true}
            className={c.tableRow}
            style={{ cursor: onClick || onDoubleClick ? 'pointer' : 'auto' }}>
            {renderRow(item, columns)}
          </TableRow>
        </Fragment>
      );
    });
  };

  const handleSort = (event: any, property: string) => {
    setOrderBy(property);

    let newOrder: 'asc' | 'desc' = 'asc';

    if (property) {
      if (orderBy === property) {
        newOrder = order === 'asc' ? 'desc' : 'asc';
      } else {
        newOrder = 'asc';
      }
    }

    setOrder(newOrder);

    // сортировка
    const filteredData = Utils.sort<typeof data>(
      newOrder === 'asc' ? true : false,
      filterData,
      property as keyof typeof data,
    );
    setFilterData(filteredData);
  };

  return (
    <div className={c.reportTable}>
      <TableContainer className={c.tableContainer}>
        <Table stickyHeader={true}>
          <EnhancedTableHeader
            className={c.head}
            columns={columns}
            order={order}
            orderBy={orderBy}
            onSort={handleSort}
          />

          <TableBody>{renderRows()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default observer(CustomTable);
