import { ReactNode, useState } from 'react';
import { observer } from 'mobx-react';

import { ThemeProvider } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';

import { materialTheme } from 'theme';
import useStyles from './styles';

interface IProps {
  startDate: Date | null;
  finishDate: Date | null;
  handleChangeStartDate: (date: MaterialUiPickersDate, value: string | null | undefined) => void;
  handleChangeFinishDate: (date: MaterialUiPickersDate, value: string | null | undefined) => void;
  handleChangeErrorStart: (withError: boolean) => void;
  handleChangeErrorFinish: (withError: boolean) => void;
}

const INVALID_DATA_LABEL = 'Неверный формат даты';

const CustomDateRange = (props: IProps) => {
  const {
    startDate,
    finishDate,
    handleChangeStartDate,
    handleChangeFinishDate,
    handleChangeErrorStart,
    handleChangeErrorFinish,
  } = props;

  const [textErrorStart, setTextErrorStart] = useState('');
  const [textErrorFinish, setTextErrorFinish] = useState('');

  const c = useStyles();

  const handleStartError = (error: ReactNode, value: string | number | object | Date | null | undefined) => {
    const errorInfo = error?.toString() === 'Invalid Date Format' ? INVALID_DATA_LABEL : error?.toString() ?? '';

    if (value === null || errorInfo === '') {
      setTextErrorStart(' ');
      if (handleChangeErrorStart) handleChangeErrorStart(false);
    } else {
      setTextErrorStart(errorInfo);
      if (handleChangeErrorStart) handleChangeErrorStart(true);
    }
  };

  const handleFinishError = (error: ReactNode, value: string | number | object | Date | null | undefined) => {
    const errorInfo = error?.toString() === 'Invalid Date Format' ? INVALID_DATA_LABEL : error?.toString() ?? '';

    if (value === null || error === '') {
      setTextErrorFinish(' ');
      if (handleChangeErrorFinish) handleChangeErrorFinish(false);
    } else {
      setTextErrorFinish(errorInfo);
      if (handleChangeErrorFinish) handleChangeErrorFinish(true);
    }
  };

  return (
    <ThemeProvider theme={materialTheme}>
      <div className={c.container}>
        <div className={c.titleWrapper}>с</div>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <KeyboardDatePicker
            value={startDate}
            maxDate={finishDate}
            label="Период"
            format="dd.MM.yyyy"
            cancelLabel="отмена"
            okLabel="ок"
            helperText={textErrorStart}
            onChange={handleChangeStartDate}
            className={c.textField}
            onError={handleStartError}
          />
        </MuiPickersUtilsProvider>

        <div className={c.titleWrapper}>по</div>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <KeyboardDatePicker
            value={finishDate}
            minDate={startDate}
            maxDate={finishDate}
            format="dd.MM.yyyy"
            cancelLabel="отмена"
            okLabel="ок"
            helperText={textErrorFinish}
            onChange={handleChangeFinishDate}
            className={c.textField}
            onError={handleFinishError}
          />
        </MuiPickersUtilsProvider>
      </div>
    </ThemeProvider>
  );
};

export default observer(CustomDateRange);
