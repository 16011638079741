import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  buttonUploadPicture: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: 40,
    height: 40,
    '&.MuiIconButton-root.Mui-disabled': {
      background: `${theme.palette.common.white} !important`,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export default useStyles;
