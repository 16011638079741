import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { authStore } from 'Stores';

import { Container } from '@material-ui/core';
import { Spinner } from 'Components';
import { AuthControl } from './Components';

import { Routes } from 'Shared/Constants/Constants';
import { icons } from 'Shared/Constants/icons';

import { useStyles } from './styles';

const AuthPage = () => {
  const isLoading = authStore.isLoading;
  const history = useHistory();
  const userData = authStore.userData;

  const c = useStyles();

  useEffect(() => {
    if (!userData?.id) return;
    else history.push(Routes.MAIN);
  }, [userData, history]);

  return (
    <div className={c.splashScreen}>
      <Container>
        <div className={c.container}>
          <img src={icons.logotype.img} alt={icons.logotype.alt} />

          {!isLoading ? (
            <AuthControl />
          ) : (
            <div className={c.control}>
              <Spinner />
              <p>Авторизация...</p>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default observer(AuthPage);
