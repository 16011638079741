import { useEffect } from 'react';
import { Button, Container, TextField } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { authStore, feedbackStore, mediaStore } from 'Stores';

import { Header, PreviewPhotos, UploadPhotos } from 'Components';

import { PhotoStorageLocation } from 'Shared/Enums/enums';
import { validationFeedback } from 'Shared/Utils/Validators';
import { useStyles } from './styles';
import { Routes } from 'Shared/Constants/Constants';
import { observer } from 'mobx-react';

const FeedbackPage = () => {
  const c = useStyles();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: authStore.userData?.email ?? '',
      description: '',
    },
    validationSchema: validationFeedback,
    onSubmit: values => feedbackStore.sendFeedbackData(values),
  });

  useEffect(() => {
    feedbackStore.isSuccess = false;
  }, []);

  useEffect(() => {
    if (feedbackStore.isSuccess) {
      formik.resetForm();
      setTimeout(() => {
        history.push(Routes.PROFILE);
      }, 2000);
    }
    // eslint-disable-next-line
  }, [feedbackStore.isSuccess]);

  const onPhotoLoad = (files: FileList) => {
    mediaStore.addPhoto(PhotoStorageLocation.PhotoFeedback, files);
  };

  return (
    <div className={c.feedbackPage}>
      <Container>
        <Header isProfile={true} settings={false} />

        <h1 className={c.feedbackTitle}>Написать разработчику</h1>

        <p className={c.feedbackText}>
          Если у вас возникли трудности с приложением или есть идеи, как сделать его лучше, напишите нам.
        </p>

        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth={true}
            variant="outlined"
            id="email"
            name="email"
            label="Email"
            className={c.input}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            multiline={true}
            fullWidth={true}
            variant="outlined"
            id="description"
            name="description"
            label="Сообщение"
            className={c.input}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />

          {mediaStore.photosFeedback.length ? (
            <PreviewPhotos
              data={mediaStore.photosFeedback}
              storage={PhotoStorageLocation.PhotoFeedback}
              className={c.preview}
            />
          ) : (
            ''
          )}

          <div className={c.control}>
            <Button className={c.button} variant="contained" fullWidth={true} type="submit">
              Отправить
            </Button>

            <UploadPhotos
              className={c.photo}
              icon={<AttachFileIcon className={c.iconAttach} />}
              handleChange={onPhotoLoad}
            />
          </div>
        </form>
      </Container>
    </div>
  );
};

export default observer(FeedbackPage);