import { observer } from 'mobx-react';

import { claimsStore } from 'Stores';

import PhoneInput from '../PhoneInput/PhoneInput';

const PhoneNumbers = () => {
  return (
    <>
      {claimsStore.phoneNumbers.map((phone, index) => (
        <PhoneInput phoneModel={phone} key={index} index={index} />
      ))}
    </>
  );
};

export default observer(PhoneNumbers);
