import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  preview: {
    position: 'fixed',
    bottom: 48,
    left: 4,
    height: 102,
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    maxWidth: '100%',
    padding: '0 5px',
    background: 'transparent',
  },
  block: {
    minWidth: 80,
    height: 80,
    boxShadow: `0px 0px 2px ${theme.palette.common.white}`,
    '& img': {
      width: 80,
    },
    marginRight: 5,
    position: 'relative',
    overflow: 'hidden',
    '& span': {
      position: 'absolute',
      top: 1,
      right: 1,
    },
  },
  spinner: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    color: theme.palette.primary.main,
    animationDuration: '550ms',
  },
}));

export default useStyles;
