import HTTPService from './HttpService';

import { BASE_PATH } from 'Shared/Constants/Constants';
import { IParkingData } from 'Shared/Interfaces/Interfaces';

class ParkingService extends HTTPService {
  constructor() {
    super(BASE_PATH.PARKING);
  }

  public sendParking(body: IParkingData) {
    return this.POST<string>(``, JSON.stringify(body));
  }
}

export default new ParkingService();
