import { useStyles } from './styles';

const Agreement = () => {
  const c = useStyles();

  return (
    <div className={c.root}>
      <div className={c.titleBlock}>
        <p className={c.title}>СОГЛАСИЕ</p>
        <p>на обработку персональных данных пользователя приложения</p>
      </div>

      <p className={c.text}>
        Настоящим я во исполнение требований Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных» (с
        изменениями и дополнениями) свободно, своей волей и в своих интересах даю своё согласие на обработку
        персональных данных пользователя приложения (далее – Согласие) обществу с ограниченной ответственностью «Рубиус
        Тех» (ИНН 7024023627, КПП 701701001, ОГРН 1057004447807, адрес: Россия, Томская область, г. Томск, ул. Нахимова,
        д. 13/1, оф. 309 (далее – Оператор, Компания), на обработку своих персональных данных, указанных при направлении
        любых сообщений через приложение «Наш Томск» (далее – Приложение).
      </p>

      <div className={c.text}>
        <p>
          1. Под персональными данными понимается любая информация, относящаяся ко мне как к субъекту персональных
          данных, в том числе (далее – персональные данные):
        </p>
        <ul>
          <li>– фамилия, имя, отчество, номер телефона, личный e-mail;</li>
          <li>
            – данные о пользовательском устройстве (среди которых разрешение, версия и другие атрибуты, характеризующие
            пользовательское устройство);
          </li>
          <li>– параметры сессии;</li>
          <li>– данные о времени посещения;</li>
          <li>– IP-адрес;</li>
          <li>– данные о геолокации пользователя;</li>
          <li>
            – иная информация, обладающая признаками персональных данных в соответствии с действующим законодательством.
          </li>
        </ul>
      </div>

      <div className={c.text}>
        <p>
          2. Я согласен (согласна) с тем, что в рамках обработки персональных данных Оператор вправе осуществлять сбор,
          запись, систематизацию, использование, передачу третьим лицам (включая, но не ограничиваясь: организациям
          владельцам-серверов; органам местного самоуправления), получение, обработку, хранение, уточнение (обновление,
          изменение), обезличивание, блокирование, удаление, уничтожение моих персональных данных путём ведения баз
          данных автоматизированным, механическим, ручным способом в целях:
        </p>
        <ul>
          <li>
            – моей идентификации, в том числе для регистрации в Приложении, для предоставления доступа к функциям
            Приложения, для исполнения соглашений и договоров с Оператором;
          </li>
          <li>
            – информирования и предоставления персонализированных услуг и исполнение соглашений и договоров с
            Оператором;
          </li>
          <li>
            – обеспечения связи со мной, в том числе направления уведомлений, запросов и информации, касающихся
            использования Приложения, исполнения соглашений и договоров, а также обработки запросов и заявок от меня;
          </li>
          <li>– улучшения качества Приложения, удобства его использования, разработки новых функций;</li>
          <li>
            – технической поддержки при обработке информации, документации и персональных данных с использованием
            средств автоматизации и без такого использования;{' '}
          </li>
          <li>– подтверждения достоверности и полноты моих персональных данных;</li>
          <li>– обеспечение соблюдения законов и иных нормативных правовых актов в области персональных данных.</li>
        </ul>
      </div>

      <div className={c.text}>
        <p>
          3. Я уведомлен(а) и согласен (согласна), что работа с информационными системами персональных данных
          осуществляется Компанией по алгоритму, указанному в п. 2 настоящего Согласия. Используемые способы обработки,
          включая, но не ограничиваясь: автоматическое получение персональной информации, указанной в п. 1, из учётной
          записи на Официальном интернет-портале государственных услуг, автоматическое обновление персональной
          информации, указанной в п. 1, периодические контакты со мной посредством телефонной связи (включая мобильную
          связь), посредством электронной почты или сети Интернет.
        </p>
      </div>

      <div className={c.text}>
        <p>
          4. Я уведомлен(а) о том, что пользователями персональных данных является Компания и его работники (а также
          лица, привлечённые на условиях гражданско-правового договора).
        </p>
      </div>

      <div className={c.text}>
        <p>5. Я ознакомлен(а), что:</p>
        <ul>
          <li>
            – настоящее согласие на обработку моих персональных данных даётся до момента достижения целей обработки
            персональных данных и может быть отозвано посредством направления электронного заявления по адресу
            электронной почты: info@rubius.com. Датой отзыва считается день, следующий за днём отправки электронного
            заявления об отзыве согласия на обработку персональных данных по адресу электронной почты;
          </li>
          <li>
            – имею право на доступ к своим персональным данным, требовать уточнения (обновление, изменение) моих
            персональных данных, а также удаления и уничтожения моих персональных данных в случае их обработки способом,
            нарушающим мои законные права и интересы.
          </li>
        </ul>
      </div>

      <div className={c.text}>
        <p>
          6. Настоящее Согласие признаётся мной как письменное согласие на обработку моих персональных данных, данным в
          соответствии со ст. 9 Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных».
        </p>
      </div>

      <div className={c.text}>
        <p>
          7. Настоящим Согласием я подтверждаю, что являюсь субъектом предоставляемых персональных данных, персональные
          данные предоставлены мною лично, а также подтверждаю достоверность, полноту, точность предоставляемых данных,
          предоставленные персональные данные не нарушают действующее законодательство Российской Федерации, законные
          права и интересы третьих лиц.
        </p>
      </div>

      <div className={c.text}>
        <p>
          8. Я осознаю, что использование Приложения и создание личной учётной записи в Приложении, означает моё
          письменное согласие с условиями, описанными в настоящем Согласии, а также действующей Политики
          конфиденциальности.
        </p>
      </div>

      <div className={c.text}>
        <p>
          9. Настоящее согласие вступает в силу с момента моего использования Приложения и действует до момента
          достижения целей обработки персональных данных или до момента отзыва мной согласия на обработку персональных
          данных в зависимости от того, какой из указанных юридических фактов наступит раньше.
        </p>
      </div>

      <div className={c.text}>
        <p>
          10. Настоящим признаю и подтверждаю, что права и обязанности, предусмотренные Федеральным законом «О
          персональных данных» от 27.07.2006 № 152-ФЗ, мне известны и понятны.
        </p>
      </div>

      <div className={c.text}>
        <p>
          11. Компания вправе в любой момент без предварительного уведомления изменять, дополнять или обновлять текст
          настоящего Согласия в той или иной части. В том случае, если в настоящее Согласие будут внесены существенные
          изменения, такое Согласие подлежит размещению в Приложении в профиле Пользователя, а соответствующие изменения
          вступают в силу с момента официальной публикации новой редакции Согласия. Если мной в письменной форме сразу
          не будет заявлено об отказе от принятия изменений, то это означает выражение согласия на обработку
          персональных данных в порядке, предусмотренном новой редакцией Согласия. Обязуюсь регулярно просматривать
          текст настоящего Согласия и проверять наличие /отсутствие изменений Согласия и даты вступления в силу таких
          изменений.
        </p>
      </div>

      <div className={c.text}>
        <p>
          В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным
          условием до обращения в суд является предъявление претензии (письменного предложения о добровольном
          урегулировании спора).
        </p>
      </div>

      <div className={c.text}>
        <p>
          12. Персональная информация, переданная мной, хранится и обрабатывается администрацией Приложения в
          соответствии с условиями Политики конфиденциальности, размещённой в Приложении.
        </p>
      </div>

      <div className={c.text}>
        <p>
          13. Я уведомлён(а) о том, что Оператор принимает необходимые правовые, организационные и технические меры или
          обеспечивает их принятие для защиты персональных данных от неправомерного или случайного доступа к ним,
          уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а
          также от иных неправомерных действий в отношении персональных данных.
        </p>
      </div>

      <div>
        <p className={`${c.text} ${c.update}`}>Дата обновления: 06.08.2021</p>
      </div>
    </div>
  );
};

export default Agreement;
