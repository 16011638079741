import { CarNumber, PhoneNumbers, SelectionViolation } from './Components';
import { Coordinates, Photos } from 'Components';

import { ICarDataDto, IDialogProps, IPhotoView } from 'Shared/Interfaces/Interfaces';
import FullScreenImage from 'Components/FullScreenImage';

export const AdDialog = (photos: string[], address: string, coordinates: { lat: number; lng: number }) => {
  const transformedPhotos = photos.map(p => {
    return { photo: p, isVideo: false };
  });

  const result: IDialogProps = {
    buttonName: 'Отправить',
    items: [
      {
        title: '1. Какие телефоны указаны в объявлении?',
        component: <PhoneNumbers />,
        id: 1,
      },
      {
        title: '2. Фото объявления и места',
        component: (
          <>
            <Photos photos={transformedPhotos} />
            <FullScreenImage photos={transformedPhotos} />
          </>
        ),
        id: 2,
      },
      {
        title: '3. Координаты места',
        component: <Coordinates address={address} coordinates={coordinates} withMap={false} />,
        id: 3,
      },
    ],
  };
  return result;
};

export const CarDialog = (
  photos: string[],
  address: string,
  coordinates: { lat: number; lng: number },
  carData: ICarDataDto
) => {
  const transformedPhotos = photos.map(p => {
    return { photo: p, isVideo: false };
  });

  const result: IDialogProps = {
    buttonName: 'Отправить',
    items: [
      {
        id: 1,
        title: '1. Госномер автомобиля-нарушителя',
        component: <CarNumber carNumber={carData.carNumber} regionCode={carData.regionCode} isOnlyView={false} />,
      },
      {
        title: '2. Фото автомобиля и места',
        component: (
          <>
            <Photos photos={transformedPhotos} />
            <FullScreenImage photos={transformedPhotos} />
          </>
        ),
        id: 2,
      },
      {
        title: '3. Категория правонарушения',
        component: <SelectionViolation />,
        id: 3,
      },
      {
        title: '4. Координаты места',
        component: <Coordinates address={address} coordinates={coordinates} withMap={false} />,
        id: 4,
      },
    ],
  };
  return result;
};

export const TrashDialog = (photos: IPhotoView[], address: string, coordinates: { lat: number; lng: number }) => {
  const result: IDialogProps = {
    buttonName: 'Отправить',
    items: [
      {
        title: '1. Фотографии и видео мусора',
        component: (
          <>
            <Photos photos={photos} />
            <FullScreenImage photos={photos} />
          </>
        ),
        id: 1,
      },
      {
        title: '2. Координаты места',
        component: <Coordinates address={address} coordinates={coordinates} withMap={false} />,
        id: 2,
      },
    ],
  };
  return result;
};
