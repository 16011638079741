import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import { claimsStore } from 'Stores';

import NumberFormat from 'react-number-format';

import { PhoneModel } from 'Shared/Models';
import useStyles from './styles';
import clsx from 'clsx';

interface IProps {
  phoneModel: PhoneModel;
  index: number;
}

const PhoneInput = ({ phoneModel, index }: IProps) => {
  const c = useStyles();
  const length = claimsStore.phoneNumbers.length;

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newPhone = e.target.value.trim();
    const firstEmptyIndex = claimsStore.phoneNumbers.findIndex(p => p.phone === '');

    phoneModel.changePhone(newPhone);

    if (!!newPhone && index === firstEmptyIndex) {
      claimsStore.changePhoneNumbers(claimsStore.phoneNumbers.concat(new PhoneModel()));
    } else if (!newPhone && (index !== 0 || length > 1) && index !== firstEmptyIndex) {
      claimsStore.changePhoneNumbers(
        claimsStore.phoneNumbers.slice(0, index).concat(claimsStore.phoneNumbers.slice(index + 1, length))
      );
    }
  };

  return (
    <div className={c.phone}>
      <NumberFormat
        className={clsx(c.phoneInput, phoneModel.isValid ? '' : c.errorInput)}
        value={phoneModel.phone}
        onChange={onChange}
        format="###########"
        aria-label="dsd"
      />
    </div>
  );
};

export default observer(PhoneInput);
