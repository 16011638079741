import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tipsBlock: {
    position: 'absolute',
    top: 51,
    left: 16,
    right: 16,
    zIndex: 4,
  },
  photoTips: {
    color: theme.palette.primary.contrastText,
    padding: 5,
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    whiteSpace: 'break-spaces',
    lineHeight: 1.3,
    borderRadius: 8,
    background: theme.palette.common.white,

    '@media (max-width: 355px)': {
      fontSize: 11,
    },
  },
}));

export default useStyles;
