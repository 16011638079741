import { makeStyles, Theme } from '@material-ui/core';

import { IProps } from './UserAvatar';

const useStyles = makeStyles<Theme, IProps>(theme => ({
  avatar: {
    width: props => theme.spacing(props.spacing),
    height: props => theme.spacing(props.spacing),
    border: `1.5px solid ${theme.palette.primary.main}`,
  },
  img: {
    width: props => theme.spacing(props.spacing),
    fontSize: '5rem',
  },
}));

export default useStyles;
