import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'Shared/Constants/Colors';

export interface IButtonStyles {
  width?: string;
  height?: string;
  background?: string;
  boxShadow?: string;
  color?: string;
  fontSize?: string;
  border?: string;
  padding?: string;
}

const useStyles = makeStyles<Theme, IButtonStyles>(theme => ({
  buttonApp: props => ({
    width: props.width ? props.width : '100%',
    height: props.height ? props.height : '48px',
    background: props.background ? props.background : COLORS.BaseGreen,
    boxShadow: props.boxShadow ? props.boxShadow : '0px 3px 0px #1A6334',
    borderRadius: '24px',
    border: props.border ? props.border : 'none',
    color: props.color ? props.color : theme.palette.common.white,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: props.fontSize ? props.fontSize : '17px',
    lineHeight: '20px',
    letterSpacing: '-0.5px',
    fontFamily: theme.typography.fontFamily,
    padding: props.padding ? props.padding : '6px 8px',
    '&:hover': {
      background: props.background ? props.background : COLORS.BaseGreen,
    },
    '&:disabled': {
      background: 'rgb(40 131 73 / 62%)',
      boxShadow: 'rgb(26 99 52 / 58%) 0px 3px 0px',
    },
  }),
}));

export default useStyles;
