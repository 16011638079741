import { observer } from 'mobx-react';
import BackspaceIcon from '@material-ui/icons/Backspace';
import clsx from 'clsx';

import { mediaStore } from 'Stores';

import Spinner from '../Spinner/Spinner';

import { IPhoto } from 'Shared/Interfaces/Interfaces';
import { PhotoStorageLocation } from 'Shared/Enums/enums';

import useStyles from './styles';

interface IProps {
  data: IPhoto[];
  storage: PhotoStorageLocation;
  className?: string;
  removeFromTheEnd?: boolean;
}

const PreviewPhotos = (props: IProps) => {
  const { data, storage, className, removeFromTheEnd = false } = props;

  const c = useStyles();

  const removeItem = (id: string) => mediaStore.removePhoto(storage, id);

  return (
    <div className={clsx(c.preview, className)}>
      {data.map((item: IPhoto, i) => (
        <div key={item.id} className={c.block}>
          {item.preview === '' ? (
            <Spinner className={c.spinner} />
          ) : (
            <>
              <img src={item.preview} alt="" />
              {removeFromTheEnd ? (
                i === data.length - 1 && (
                  <span onClick={() => removeItem(item.id)}>
                    <BackspaceIcon color="error" />
                  </span>
                )
              ) : (
                <span onClick={() => removeItem(item.id)}>
                  <BackspaceIcon color="error" />
                </span>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default observer(PreviewPhotos);
