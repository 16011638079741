import { useStyles } from './styles';

const Policy = () => {
  const c = useStyles();

  return (
    <div className={c.root}>
      <div className={c.titleBlock}>
        <p className={c.title}>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</p>
      </div>

      <div className={c.text}>
        <p>
          Политика конфиденциальности персональной информации (далее – Политика) действует в отношении всей информации,
          которую обществу с ограниченной ответственностью «Рубиус Тех» (ИНН 7024023627, КПП 701701001, ОГРН
          1057004447807, адрес: Россия, Томская область, г. Томск, ул. Нахимова, д. 13/1, оф. 309 (далее – Оператор)
          может получить о Пользователе во время использования им приложения Оператора «Наш Томск» (далее –
          «Приложение») и в ходе исполнения любых соглашений и договоров с Пользователем.
        </p>
        <p>
          Использование Приложения означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в
          ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен
          воздержаться от использования Приложения.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>1. Персональная информация Пользователей</p>
        <p>1.1. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:</p>
        <p>
          1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно в процессе
          использования Приложения, включая персональные данные Пользователя. Информация предоставляется Пользователем
          на его усмотрение посредством авторизации через Единую систему идентификации и аутентификации (ЕСИА).
        </p>
        <p>
          1.1.2. Данные, которые автоматически передаются Приложению в процессе его использования с помощью
          установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, технические
          характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к
          Приложению и иная подобная информация.
        </p>
        <p>
          1.1.3. Иная информация о Пользователе, обработка которой предусмотрена действующим законодательством и
          согласием на обработку.
        </p>
        <p>1.2. Настоящая Политика применима только к информации, обрабатываемой в ходе использования Приложения.</p>
        <p>
          1.3. Оператор не проверяет достоверность персональной информации, предоставляемой Пользователем, и не имеет
          возможности оценивать его дееспособность. Однако Оператор исходит из того, что пользователь предоставляет
          достоверную и достаточную персональную информацию и поддерживает эту информацию в актуальном состоянии.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>2. Цели обработки персональной информации Пользователей</p>
        <p>
          2.1. Оператор собирает и хранит только ту персональную информацию, которая необходима для использования
          Приложения и получения доступа к Приложению, в том числе для исполнения соглашений и договоров с
          Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение
          персональной информации в течение определённого законом срока.
        </p>
        <p>2.2. Персональную информацию Пользователя Оператор обрабатывает в следующих целях:</p>
        <p>
          2.2.1. идентификации стороны, в том числе для регистрации в Приложении, для предоставления доступа к функциям
          Приложения, для исполнения соглашений и договоров с Оператором.
        </p>
        <p>
          2.2.2. предоставления Пользователю персонализированных услуг и исполнение соглашений и договоров с Оператором.
        </p>
        <p>
          2.2.3. связи с Пользователем, в том числе направления уведомлений, запросов и информации, касающихся
          использования Приложения, исполнения соглашений и договоров, а также обработки запросов и заявок от
          Пользователя.
        </p>
        <p>
          2.2.4. улучшения качества Приложения, удобства его использования, разработка новых функций для Приложения;
        </p>
        <p>
          2.2.5. проведению опросов и исследований, направленных на выявление удовлетворённости/неудовлетворённости
          Пользователя, постоянного совершенствования уровня предоставляемых услуг и работы Приложения;
        </p>
        <p>2.2.6. подтверждения достоверности и полноты персональных данных, представленных Пользователем.</p>
        <p>
          2.2.7. в иных целях, предусмотренных Согласием на обработку персональных данных, размещённым в Приложении.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>3. Условия обработки персональной информации Пользователей и её передачи третьим лицам</p>
        <p>
          3.1. Оператор хранит персональную информацию Пользователей в соответствии с данной Политикой
          конфиденциальности и действующим законодательством.
        </p>
        <p>
          3.2. В отношении персональной информации Пользователя сохраняется её конфиденциальность, кроме случаев
          добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.
        </p>
        <p>3.3. Оператор вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:</p>
        <p>3.3.1. Пользователь выразил согласие на такие действия.</p>
        <p>3.3.2. Передача необходима для использования Пользователем определённых функций Приложения.</p>
        <p>3.3.3. В случаях, прямо предусмотренных законом.</p>
        <p>
          3.3.4. В целях обеспечения возможности защиты прав и законных интересов Оператора или третьих лиц в случаях,
          когда Пользователь нарушает настоящую Политику, либо документы, содержащие условия использования Приложения.
        </p>
        <p>
          3.4. При обработке персональных данных Пользователей Оператор руководствуется Федеральным законом РФ «О
          персональных данных», иными Федеральными законами и нормативными правовыми актами Правительства РФ,
          ведомственными нормативными правовыми актами, на основании которых осуществляется обработка персональных
          данных и локальными актами индивидуального предпринимателя.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>4. Изменение и удаление персональной информации. Обязательное хранение данных</p>
        <p>
          4.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную
          информацию или её часть, воспользовавшись функцией редактирования персональных данных.
        </p>
        <p>
          4.2. Пользователь также может удалить предоставленную им в рамках определённой учётной записи персональную
          информацию путём соответствующего запроса в техническую поддержку по адресу: info@rubius.com.
        </p>
        <p>
          4.3. Права, предусмотренные пп. 4.1. и 4.2. настоящей Политики могут быть ограничены в соответствии с
          требованиями законодательства. В частности, такие ограничения могут предусматривать обязанность Оператора
          сохранить изменённую или удалённую Пользователем информацию на срок, установленный законодательством, и
          передать такую информацию в соответствии с законодательно установленной процедурой государственному органу.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>5. Меры, применяемые для защиты персональной информации Пользователя</p>
        <p>
          Оператор принимает необходимые и достаточные организационные и технические меры для защиты персональной
          информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
          копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>6. Изменение Политики конфиденциальности. Применимое законодательство</p>
        <p>
          Оператор имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в
          актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента
          её размещения, если иное не предусмотрено новой редакцией Политики.
        </p>
      </div>

      <div className={c.text}>
        <p className={c.bold}>7. Разрешение споров</p>
        <p>
          В случае возникновения любых разногласий или споров по поводу настоящей Политики обязательным условием до
          обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).
        </p>
        <p>
          Получатель претензии в течение 5 (пяти) рабочих дней со дня получения претензии письменно уведомляет заявителя
          претензии о результатах её рассмотрения.
        </p>
        <p>
          При недостижении согласия спор передаётся на рассмотрение в суд по месту нахождения Оператора в соответствии с
          законодательством РФ.
        </p>
      </div>

      <div>
        <p className={`${c.text} ${c.update}`}>Дата обновления: 06.08.2021</p>
      </div>
    </div>
  );
};

export default Policy;
