import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { observer } from 'mobx-react';

import { claimsStore } from 'Stores';

import { Header } from 'Components';

import { ClaimTypes } from 'Shared/Enums/enums';

import useStyles from './styles';
import { IClaimDetailDto } from 'Shared/Interfaces/Interfaces';
import { AdDialog, CarDialog, TrashDialog } from './dialogItems';

const ClaimDetailPage = () => {
  const location = useLocation();
  const c = useStyles();

  const [claim, setClaim] = useState<IClaimDetailDto | undefined>(undefined);
  const [claimType, setClaimType] = useState<ClaimTypes | undefined>(undefined);

  useEffect(() => {
    async function getClaimDetail() {
      if (location.search) {
        const transformedSearch = location.search.replaceAll('&amp;', '&');

        const params = new URLSearchParams(transformedSearch);

        const id = params.get('id');
        const type = params.get('type');

        if (id && type) {
          const currentType = +type;
          setClaimType(currentType);
          switch (currentType) {
            case ClaimTypes.ParkingClaim:
              const car = await claimsStore.getClaim(+id);
              setClaim(car);
              break;
            case ClaimTypes.AdvertisingClaim:
              const adv = await claimsStore.getClaim(+id);
              setClaim(adv);
              break;
            case ClaimTypes.RubbishClaim:
              const trash = await claimsStore.getClaim(+id);
              setClaim(trash);
              break;
          }
        }
      }
    }

    getClaimDetail();
  }, [location]);

  const getTitle = () => {
    switch (claimType) {
      case ClaimTypes.ParkingClaim:
        return 'Сообщение о парковке в неположенном месте';
      case ClaimTypes.AdvertisingClaim:
        return 'Сообщение о рекламе в неположенном месте';
      case ClaimTypes.RubbishClaim:
        return 'Сообщение о мусоре в неположенном месте';
    }

    return 'Результат фиксации';
  };

  const getDialogItems = () => {
    if (!claim) return { items: [] };

    switch (claimType) {
      case ClaimTypes.ParkingClaim:
        return CarDialog(claim, c.phoneText);
      case ClaimTypes.AdvertisingClaim:
        return AdDialog(claim, c.phoneText);
      case ClaimTypes.RubbishClaim:
        return TrashDialog(claim);
    }

    return { items: [] };
  };

  return (
    <div className={c.dialog}>
      <Header isProfile={true} settings={false} />

      <Container>
        <h1 className={c.title}>{getTitle()}</h1>

        <ul className={c.dialogBlock}>
          {getDialogItems()!.items.map(item => (
            <li key={item.id}>
              <p className={c.dialogTitle}>{item.title}</p>
              <div className={c.dialogBlockContainer}>{item.component}</div>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};

export default observer(ClaimDetailPage);
