import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(theme => ({
  fullScreenImageWrapper: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 10005,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[800],

    '& .react-transform-wrapper': {
      width: '100% !important',
      height: '100% !important',
    },

    '& .react-transform-component': {
      width: '100% !important',
      height: '100% !important',
      justifyContent: 'center',
    },

    '& .react-transform-element': {
      width: '100% !important',
      height: '100% !important',
    },
  },
  taskImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  arrowIconWrapper: {
    width: 40,
    height: '100%',
    position: 'absolute',
    zIndex: 999,
    top: 0,
    cursor: 'pointer',
    color: theme.palette.common.white,
    opacity: 0.7,
    '& svg': {
      width: '100%',
      height: '100%',
    },

    '&:hover': {
      opacity: 0.7,
    },
  },
  arrowBack: {
    left: theme.spacing(2),
    right: 'unset',
  },
  arrowNext: {
    left: 'unset',
    right: theme.spacing(2),
  },
}));

export default useStyles;
