import { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import { dashboardStore } from 'Stores';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ButtonApp, CustomDateRange } from 'Components';

import Utils from 'Shared/Utils/Utils';

import useStyles from './styles';

const Filter = () => {
  const c = useStyles();

  const [state, setState] = useState('');

  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isFinishDateValid, setIsFinishDateValid] = useState(true);

  const isDisabledButton = useMemo(() => !isStartDateValid || !isFinishDateValid, [
    isStartDateValid,
    isFinishDateValid,
  ]);

  const onClickShow = () => {
    dashboardStore.postTotalStatistics();
  };

  const handleChangeState = (event: React.ChangeEvent<any>) => {
    const value = event.target.value;
    value === '-' ? dashboardStore.filter.setState(null) : dashboardStore.filter.setState(value);
    setState(value);
  };

  const status = ['-', 2, 3, 1, 4, 5];

  const handleChangeStartDate = (date: MaterialUiPickersDate, value: string | null | undefined) => {
    const newDate = (date ? moment(date) : moment()).startOf('date').toDate();
    dashboardStore.filter.setStartDate(newDate);
  };

  const handleChangeFinishDate = (date: MaterialUiPickersDate, value: string | null | undefined) => {
    const newDate = (date ? moment(date) : moment()).endOf('date').toDate();
    dashboardStore.filter.setFinishDate(newDate);
  };

  const handleChangeErrorStart = (withError: boolean) => {
    setIsStartDateValid(!withError);
  };

  const handleChangeErrorFinish = (withError: boolean) => {
    setIsFinishDateValid(!withError);
  };

  return (
    <div className={c.filter}>
      <CustomDateRange
        handleChangeStartDate={handleChangeStartDate}
        handleChangeFinishDate={handleChangeFinishDate}
        startDate={dashboardStore.filter.startDate}
        finishDate={dashboardStore.filter.finishDate}
        handleChangeErrorStart={handleChangeErrorStart}
        handleChangeErrorFinish={handleChangeErrorFinish}
      />

      <div className={c.secondFilter}>
        <FormControl className={c.form}>
          <InputLabel id="demo-simple-select-label" className={c.label}>
            Статус
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state}
            onChange={handleChangeState}
            className={c.select}>
            {status.map((item, index) =>
              index === 0 ? (
                <MenuItem value="">
                  <em>Не выбрано</em>
                </MenuItem>
              ) : (
                <MenuItem key={index} value={item}>
                  {Utils.getStateName(item)}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>

        <div className={c.buttonWrapperShow}>
          <ButtonApp disabled={isDisabledButton} onClick={onClickShow}>
            Показать
          </ButtonApp>
        </div>
      </div>
    </div>
  );
};

export default observer(Filter);
