import React from 'react';
import { observer } from 'mobx-react';

import { claimsStore } from 'Stores';

import { icons } from 'Shared/Constants/icons';

import useStyles from './styles';

interface IProps {
  carNumber: string;
  regionCode: string;
  isOnlyView: boolean;
}

const CarNumber = ({ carNumber, regionCode, isOnlyView }: IProps) => {
  const c = useStyles();

  const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>): void => claimsStore.changeCarNumber(e.target.value);

  const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>): void => claimsStore.changeRegionCode(e.target.value);

  return (
    <div className={c.carNumber}>
      <div className={c.carNumberBlock}>
        <input className={c.numberInput} value={carNumber} onChange={onChangeNumber} readOnly={isOnlyView} />
        <div className={c.codeBlock}>
          <input className={c.regionCode} value={regionCode} onChange={onChangeCode} readOnly={isOnlyView} />
          <div className={c.flag}>
            <p>RUS</p>
            <img src={icons.flag.img} alt={icons.flag.alt} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CarNumber);
