import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // animation: '$root 0.5s forwards ease-out',
    marginBottom: 30,
    '& p, & h1': {
      textAlign: 'center',
    },
  },
  '@keyframes root': {
    '0%': {
      opacity: 0,
      marginTop: '-100px',
    },
    '100%': {
      opacity: 1,
      marginTop: '0px',
    },
  },
  container: {
    padding: '100px 40px',
  },
  img: {
    marginBottom: 20,
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    letterSpacing: '-0.24px',
    color: theme.palette.common.black,
    textAlign: 'left',
    marginTop: 30,
    '&:nth-child(2)': {
      marginTop: 6,
      marginBottom: 36,
    },
  },
  button: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    boxShadow: 'none',
  },
}));

export default useStyles;
