import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  imageEditorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    position: 'absolute',
    top: theme.spacing(2),
    right: 8,
    zIndex: 1000,
  },
  actionButton: {
    backgroundColor: theme.palette.grey[600],
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
    maxWidth: 30,
    '&:hover': {
      opacity: 0.9,
    },
  },
  iconButton: {
    color: theme.palette.common.white,
    padding: 0,
    borderRadius: theme.spacing(1),
    margin: '0px !important',
  },
  toolIcon: {
    color: theme.palette.common.white,
    width: 30,
    height: 30,
  },
  muiToolIcon: {
    width: 22,
    height: 30,
    backgroundColor: theme.palette.grey[600],
    borderRadius: theme.spacing(1),
  },
}));

export default useStyles;
