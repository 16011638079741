
import { observer } from 'mobx-react';
import { fullImageStore } from 'Stores';

import useStyles from './styles';
import { icons } from 'Shared/Constants/icons';
import IconButtonWrapper from 'Components/Buttons/IconButtonWrapper';
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
  zoomIn: (step?: number | undefined, animationTime?: number | undefined, animationType?: any) => void;
  zoomOut: (step?: number | undefined, animationTime?: number | undefined, animationType?: any) => void;
  resetTransform: () => void;
}

const ImageEditorPanel = (props: IProps) => {
  const c = useStyles();

  const { zoomIn, zoomOut, resetTransform } = props;

  const handleImageClose = () => {
    fullImageStore.clearSelectedImage();
  };

  const handleZoomIn = () => {
    zoomIn();
  };

  const handleZoomOut = () => {
    zoomOut();
  };

  const handleResetTransform = () => {
    resetTransform();
  };

  return (
    <>
      <div className={c.imageEditorWrapper}>
        <div className={c.actionButton}>
          <IconButtonWrapper
            className={c.iconButton}
            icon={<CloseIcon className={c.muiToolIcon} />}
            hint="Toggle full screen"
            onClick={handleImageClose}
          />
        </div>

        <div className={c.actionButton}>
          <IconButtonWrapper
            className={c.iconButton}
            icon={<img className={c.toolIcon} src={icons.imageBar.zoomIn.img} alt="" />}
            hint="Zoom in"
            onClick={handleZoomIn}
          />
        </div>

        <div className={c.actionButton}>
          <IconButtonWrapper
            className={c.iconButton}
            icon={<img className={c.toolIcon} src={icons.imageBar.zoomOut.img} alt="" />}
            hint="Zoom Out"
            onClick={handleZoomOut}
          />
        </div>

        <div className={c.actionButton}>
          <IconButtonWrapper
            className={c.iconButton}
            icon={<img className={c.toolIcon} src={icons.imageBar.resetZoom.img} alt="" />}
            hint="Reset zoom"
            onClick={handleResetTransform}
          />
        </div>
      </div>
    </>
  );
};

export default observer(ImageEditorPanel);
