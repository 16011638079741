import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(theme => ({
  link: {
    '& li': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '15px',
      lineHeight: '19px',
      letterSpacing: '-0.24px',
      color: theme.palette.secondary.main,
      textAlign: 'left',
      marginBottom: 15,
    },
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
