import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { WrapperPage, UploadPhotos } from 'Components';
import { PhotoTips, VideoPlayer } from './Components';

import { appStore, claimsStore, mediaStore } from 'Stores';

import { PhotoStorageLocation, RoutesNumber } from 'Shared/Enums/enums';
import { MAX_NUMBER_PHOTO_FILES, Routes } from 'Shared/Constants/Constants';
import { AdTips, CarTips, TrashTips } from './tips';

import useStyles from './styles';
import useLongPress from 'Shared/Utils/UseLongPress';

import exifr from 'exifr';

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: 'environment' },
};

const PhotoPage = () => {
  const c = useStyles();
  const history = useHistory();

  const storage = PhotoStorageLocation.Photos;

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [isCameraWorking, setIsCameraWorking] = useState(false);
  const [galleryPhotoPreview, setGalleryPhotoPreview] = useState<string>('');
  const [cameraPhotoPreview, setCameraPhotoPreview] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<FileList | null>(null);

  const [isVideo, setIsVideo] = useState(false);
  const [isVideoFull, setIsVideoFull] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [videoParts, setVideoParts] = useState<BlobPart[]>([]);
  const [videoPreview, setVideoPreview] = useState<string>('');

  useEffect(() => {
    if (!videoRef || !videoRef.current || videoRef.current.srcObject) return;

    // сначала отчистим предыдущие стримы
    navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS).then((stream: any) => {
      if (stream) {
        stream.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    });

    let localMediaStream = mediaStream;
    navigator.mediaDevices
      .getUserMedia(CAPTURE_OPTIONS)
      .then((stream: any) => {
        localMediaStream = stream;
        setMediaStream(stream);
        videoRef!.current!.srcObject = stream;
      })
      .catch((err: any) => {
        // alert("Ошибка работы с камерой. Проверьте разрешения приложения в настройках");
        // alert("The following error occured: " + err);
        return;
      });

    return () => {
      if (localMediaStream) {
        localMediaStream.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tips = useMemo(() => {
    switch (appStore.routeNumber) {
      case RoutesNumber.CarRoute:
        return CarTips;
      case RoutesNumber.AdRoute:
        return AdTips;
      case RoutesNumber.TrashRoute:
        return TrashTips;
      default:
        return ['', ''];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStore.routeNumber]);

  const handleCanPlay = () => {
    if (!videoRef.current) return;
    setIsCameraWorking(true);
  };

  const onTakePhoto = (e: any) => {
    if (!videoRef.current || !canvasRef.current) return;
    const manyFiles = mediaStore.checkLengthPhotos(storage);
    if (manyFiles) return;

    const context = canvasRef.current.getContext('2d');
    if (context && videoRef.current) {
      canvasRef.current.width = videoRef.current.clientWidth;
      canvasRef.current.height = videoRef.current.clientHeight;
      context.drawImage(videoRef.current, 0, 0, videoRef.current.clientWidth, videoRef.current.clientHeight);
      setCameraPhotoPreview(canvasRef.current.toDataURL('image/png'));
    }
  };

  const onTakeVideo = (e: any) => {
    if (!videoRef.current || !canvasRef.current || !mediaStream) return;

    const manyFiles = mediaStore.checkLengthPhotos(storage);
    if (manyFiles) return;

    setIsVideo(true);

    const context = canvasRef.current.getContext('2d');
    if (context && videoRef.current) {
      canvasRef.current.width = videoRef.current.clientWidth;
      canvasRef.current.height = videoRef.current.clientHeight;
      context.drawImage(videoRef.current, 0, 0, videoRef.current.clientWidth, videoRef.current.clientHeight);
      setVideoPreview(canvasRef.current.toDataURL('image/png'));
    }

    // set MIME type of recording as video/webm
    const mediaRecorderL = new MediaRecorder(mediaStream, { mimeType: 'video/webm' });
    setMediaRecorder(mediaRecorderL);

    const blobsRecorded: BlobPart[] = [];
    // event : new recorded video blob available
    mediaRecorderL.addEventListener('dataavailable', (ev: any) => {
      blobsRecorded.push(ev.data);
    });

    // event : recording stopped & all blobs sent
    mediaRecorderL.addEventListener('stop', () => {
      // create local object URL from the recorded video blobs
      const videoLocal = URL.createObjectURL(new Blob(blobsRecorded, { type: 'video/webm' }));
      setVideoParts(blobsRecorded);
      setDownloadLink(videoLocal);
      setIsVideoFull(true);
    });

    // start recording with each recorded blob having 1 second video
    mediaRecorderL.start(1000);
  };

  const onStopVideo = () => {
    setIsVideo(false);
    if (!mediaRecorder) return;

    mediaRecorder.stop();
  };

  const onOkClick = () => {
    if (!galleryPhotoPreview && !cameraPhotoPreview && !isVideoFull) return;

    if (isVideoFull) {
      mediaStore.addCameraVideo(videoParts, videoPreview);
    } else if (uploadedFile) mediaStore.addPhoto(storage, uploadedFile);
    else mediaStore.addCameraPhoto(cameraPhotoPreview);

    onCancelClick();

    const manyFiles = mediaStore.checkLengthPhotos(storage);
    if (manyFiles) {
      appStore.hideNotification();
      history.push(Routes.LOCATION);
    }
  };

  const onCancelClick = () => {
    clearPhoto();
    clearVideo();
  };

  const clearPhoto = () => {
    setGalleryPhotoPreview('');
    setCameraPhotoPreview('');
    setUploadedFile(null);

    const context = canvasRef?.current?.getContext('2d');
    if (!context) return;

    context!.fillStyle = 'black';
    context!.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.canvas.width = 0;
    context.canvas.height = 0;
  };

  const clearVideo = () => {
    setDownloadLink(null);
    setIsVideoFull(false);
    setVideoParts([]);
  };

  const onPhotoLoad = async (files: FileList) => {
    const imageSrc = URL.createObjectURL(files[0]);
    setUploadedFile(files);
    setGalleryPhotoPreview(imageSrc);

    if (!claimsStore.imageCoordinates) {
      try {
        const { latitude, longitude } = await exifr.gps(files[0]);
        claimsStore.getImageCoordinates({ lat: latitude, lng: longitude });
      } catch (e) {}
    }
  };

  const longPressEvent = useLongPress(onTakeVideo, onTakePhoto, { shouldPreventDefault: true, delay: 500 });

  const onBack = () => {
    if (mediaStore.medias.length > 0) {
      mediaStore.removePhoto(PhotoStorageLocation.Photos, mediaStore.medias[mediaStore.medias.length - 1].id);
      return;
    }

    if (galleryPhotoPreview || cameraPhotoPreview || isVideoFull) {
      onCancelClick();
      return;
    }

    history.goBack();
  };

  return (
    <WrapperPage
      title="Фото нарушения"
      to={Routes.LOCATION}
      className={c.wrapper}
      disabledButton={mediaStore.medias.length < 2}
      withNextButton={mediaStore.medias.length === MAX_NUMBER_PHOTO_FILES}
      onBack={onBack}>
      <div className={c.camera}>
        <PhotoTips numberOfPhotos={mediaStore.medias.length} tips={tips} />
        <canvas ref={canvasRef} id="canvas" style={{ visibility: 'hidden', position: 'absolute' }} />

        {!isVideoFull && !isVideo && !galleryPhotoPreview && !cameraPhotoPreview && (
          <div>
            {appStore.routeNumber === RoutesNumber.TrashRoute && (
              <Button className={c.photoBtn} {...longPressEvent}>
                <span className={c.photoCircle} />
              </Button>
            )}

            {appStore.routeNumber !== RoutesNumber.TrashRoute && (
              <Button className={c.photoBtn} onClick={onTakePhoto}>
                <span className={c.photoCircle} />
              </Button>
            )}
          </div>
        )}

        {!isVideoFull && isVideo && (
          <Button className={c.videoBtn} onClick={onStopVideo}>
            <span className={c.videoCircle} />
          </Button>
        )}

        {(galleryPhotoPreview || (!isCameraWorking && !isVideoFull && !cameraPhotoPreview)) && (
          <div className={c.blackScreen} />
        )}

        <video
          style={galleryPhotoPreview || cameraPhotoPreview || isVideoFull ? { visibility: 'hidden' } : {}}
          ref={videoRef}
          className={c.videoBlock}
          onCanPlay={handleCanPlay}
          autoPlay={true}
          playsInline={true}
          muted={true}
        />

        {galleryPhotoPreview && (
          <div className={c.photoPreviewBlock}>
            <img src={galleryPhotoPreview} alt="" className={c.canvasBlock} />
          </div>
        )}

        {cameraPhotoPreview && <img src={cameraPhotoPreview} alt="" className={c.cameraPreviewBlock} />}

        <VideoPlayer downloadLink={downloadLink} isVideoFull={isVideoFull} className={c.videoBlock} />

        {!galleryPhotoPreview && !cameraPhotoPreview && !isVideoFull && (
          <div className={c.uploadPhotos}>
            <UploadPhotos
              multiple={false}
              disabled={mediaStore.medias.length === MAX_NUMBER_PHOTO_FILES}
              handleChange={onPhotoLoad}
              icon={<AttachFileIcon className={c.iconAttach} />}
            />
          </div>
        )}

        {(galleryPhotoPreview || cameraPhotoPreview || isVideoFull) && (
          <div className={c.wrapperFooter}>
            <Button className={c.wrapperFooterButton} onClick={onCancelClick}>
              Переснять
            </Button>
            <Button className={c.wrapperFooterButton} onClick={onOkClick}>
              Далее
            </Button>
          </div>
        )}
      </div>
    </WrapperPage>
  );
};

export default observer(PhotoPage);
