import HTTPService from './HttpService';

import { BASE_PATH, NUMBER_OF_ITEMS } from 'Shared/Constants/Constants';
import { IClaimDetailDto, IFilterData} from 'Shared/Interfaces/Interfaces';
import { IGetUserClaimsArchive, IGetUserStatistics } from 'Shared/Interfaces/IGetDto';
import { IGetTotalStatistics } from 'Shared/Interfaces/ITotalStatisticDto';

class ClaimService extends HTTPService {
  constructor() {
    super(BASE_PATH.CLAIM);
  }

  public getUserClaims(userId: number, page: number) {
    return this.GET<IGetUserClaimsArchive>(`?userId=${userId}&Limit=${NUMBER_OF_ITEMS}&Page=${page}`);
  }

  public getClaim(id: number) {
    return this.GET<IClaimDetailDto>(`/${id}`);
  }

  public getUserStatistics(userId: number) {
    return this.GET<IGetUserStatistics>(`/statistics`);
  }

  public postTotalStatistics(body: IFilterData) {
    return this.POST<IGetTotalStatistics>(`/statistics/total`, JSON.stringify(body));
  }
}

export default new ClaimService();
