import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    overflow: 'hidden',

    '& .leaflet-container': {
      height: '90%',
    },
  },

  mapAlert: {
    padding: 10,
    position: 'absolute',
    top: 85,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    width: 226,
    background: theme.palette.common.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    letterSpacing: -0.08,
    color: '#414042',
  },
}));

export default useStyles;
