import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

/** Фильтр */
export class FilterModel {
  public startDate: Date = moment()
    .startOf('months')
    .toDate();
  public finishDate: Date = moment()
    .endOf('date')
    .toDate();
  public stateId: number | null = null;

  public phone: string = '';
  public address: string = '';
  public userId: string = '';

  private readonly onFilter: () => void;

  constructor(onFilter: () => void) {
    makeObservable(this, {
      stateId: observable,
      startDate: observable,
      finishDate: observable,

      phone: observable,
      address: observable,
      userId: observable,

      setState: action,
      setStartDate: action,
      setFinishDate: action,

      setPhone: action,
      setAddress: action,
      setUserId: action,
    });

    this.onFilter = onFilter;
  }

  public setStartDate(startDate: Date) {
    this.startDate = startDate;
  }

  public setFinishDate(finishDate: Date) {
    this.finishDate = finishDate;
  }

  public setState(state: number | null) {
    this.stateId = state;
  }

  public setPhone(phone: string) {
    this.phone = phone;
    this.onFilter();
  }

  public setAddress(address: string) {
    this.address = address;
    this.onFilter();
  }

  public setUserId(userId: string) {
    this.userId = userId;
    this.onFilter();
  }
}
