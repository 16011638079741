import { MouseEvent } from 'react';
import { observer } from 'mobx-react';

import clsx from 'clsx';

import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import useStyles from './styles';
import { ITableColumn } from 'Shared/Interfaces/Interfaces';
import Filter from 'Components/Filter/Filter';

interface IProps {
  columns: ITableColumn[];
  className?: string;
  order?: 'asc' | 'desc';
  orderBy?: string;
  onSort?: (event: MouseEvent<any>, property: string) => void;
}

const EnhancedTableHeader = (props: IProps) => {
  const { columns, className, order, orderBy, onSort } = props;

  const c = useStyles();

  const renderHeader = (cols: ITableColumn[]) => {
    return cols.map((column, index) => {
      const isSortActive = orderBy === column.key;

      const handleSort = (event: MouseEvent<unknown>) => {
        if (!onSort) return;

        onSort(event, column.key);
      };

      const getLabel = () => {
        if (column.sortable) {
          return (
            <>
              <TableSortLabel
                key={index}
                active={isSortActive}
                direction={isSortActive ? order : 'asc'}
                onClick={handleSort}>
                {column.label}
              </TableSortLabel>
              {column.filter && (
                <Filter
                  className={c.filter}
                  hasValue={column.hasFilterValue ? column.hasFilterValue() : false}
                  onClear={column.onClearFilter}>
                  {column.filter()}
                </Filter>
              )}
            </>
          );
        }

        return column.label;
      };

      return (
        <TableCell
          variant="head"
          key={column.key}
          align={column.align}
          style={{ maxWidth: column.maxWidth, width: column.width }}
          sortDirection={isSortActive ? order : false}>
          <div className={className ? clsx(c.tableCell, className) : c.tableCell}>{getLabel()}</div>
        </TableCell>
      );
    });
  };

  return (
    <TableHead className={className}>
      <TableRow>{renderHeader(columns)}</TableRow>
    </TableHead>
  );
};

export default observer(EnhancedTableHeader);
