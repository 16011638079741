import { MouseEventHandler } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

interface IProps {
  mediumSize?: boolean;
  icon: JSX.Element;
  disabled?: boolean;
  hint?: string;
  onClick?: MouseEventHandler;
  className?: string;
  opacity?: number;
}

const IconButtonWrapper = (props: IProps) => {
  const { onClick, disabled, hint, className, mediumSize, opacity = 1 } = props;

  return (
    <Tooltip title={hint || ''}>
      <span style={{ alignSelf: 'center' }}>
        <IconButton
          disableRipple={true}
          size={mediumSize ? 'medium' : 'small'}
          onClick={onClick}
          disabled={disabled}
          className={className}
          style={{ opacity: disabled ? 0.6 : opacity }}>
          {props.icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default IconButtonWrapper;
