export const KeyCodes = {
  ALT: 18,
  SHIFT: 16,
  CTRL: 17,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46,
  LEFT_CMD: 91,
  RIGHT_CMD: 93,
  ESC: 27,
  ENTER: 13,

  A: 'A'.charCodeAt(0),
  B: 'B'.charCodeAt(0),
  C: 'C'.charCodeAt(0),
  D: 'D'.charCodeAt(0),
  E: 'E'.charCodeAt(0),
  F: 'F'.charCodeAt(0),
  G: 'G'.charCodeAt(0),
  N: 'N'.charCodeAt(0),
  R: 'R'.charCodeAt(0),
  S: 'S'.charCodeAt(0),
  Q: 'Q'.charCodeAt(0),
  W: 'W'.charCodeAt(0),
  Space: ' '.charCodeAt(0),
};
