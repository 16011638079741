import HTTPService from './HttpService';

import { BASE_PATH } from 'Shared/Constants/Constants';
import { IClaimDetailDto, IClaimData, IRubbishDto } from 'Shared/Interfaces/Interfaces';

class TrashService extends HTTPService {
  constructor() {
    super(BASE_PATH.TRASH);
  }

  public getTrash(id: number) {
    return this.GET<IClaimDetailDto>(`/${id}`);
  }

  public addTrashClaim(body: IClaimData) {
    return this.POST<IRubbishDto>('', JSON.stringify(body));
  }
}

export default new TrashService();
