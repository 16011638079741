import HTTPService from './HttpService';

import { BASE_PATH } from 'Shared/Constants/Constants';
import { ClaimTypes } from 'Shared/Enums/enums';
import { IGetTypeOffenses } from 'Shared/Interfaces/IGetDto';

class OffenseService extends HTTPService {
  constructor() {
    super(BASE_PATH.OFFENSE);
  }

  public getReportTypeOffenses(type: ClaimTypes) {
    return this.GET<IGetTypeOffenses[]>(`/${type}`);
  }
}

export default new OffenseService();
