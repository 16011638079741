import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  agreementsPage: {},
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabPanel: {
    padding: 16,
  },
}));
