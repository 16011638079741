import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    // animation: '$control 1s forwards ease-out',
  },
  checkbox: {
    marginLeft: 0,
    marginRight: 0,
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    textAlign: 'left',
  },
  link: {
    color: theme.palette.secondary.main,
  },
  '@keyframes control': {
    '0%': {
      opacity: '0',
      transform: 'translateY(-100%)',
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0%)',
    },
  },
}));

export default useStyles;
